import React from 'react';
import { BsPerson, BsEye, BsCheck2Square } from 'react-icons/bs';
import { AiOutlineForm } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { SummaryTable } from '@omnigenbiodata/ui';
import { HOSPTIAL_NUM_PREFIX } from '../../../../../../../../../../../core/constants/forms.constant';
import ContentCard from '../../../../../../../../../../../components/content/ContentCard';
import Heading2 from '../../../../../../../../../../../components/text/Heading2';
import { ParticipantProfile } from '../../../../../../../../../../../core/types/participants.types';

export interface EnrolmentBlockProps {
  profile: ParticipantProfile;
}

function EnrolmentBlock({ profile }: EnrolmentBlockProps) {
  const { t } = useTranslation('portal');
  return (
    <ContentCard>
      <Box mb={4}>
        <Heading2 color="textPrimary" icon={AiOutlineForm}>
          {t('participantEnrolment.title')}
        </Heading2>
        <SummaryTable
          label="Profile"
          rows={[
            {
              key: 'hospitalNo',
              label: 'IALCH ID',
              value: profile.hospitalNo,
              prefix: HOSPTIAL_NUM_PREFIX,
            },
            {
              key: 'participantRef',
              label: 'Participant Ref',
              value: profile.participantRef,
            },
            {
              key: 'firstName',
              label: 'First Name',
              value: profile.firstName,
            },
            {
              key: 'surname',
              label: 'Surname',
              value: profile.surname,
            },
            {
              key: 'sex',
              label: 'Sex',
              value: profile.sex,
            },
            {
              key: 'dob',
              label: 'D.O.B',
              value: profile.dob,
              format: 'friendlyDate',
            },
            {
              key: 'populationGroup',
              label: 'Population Group',
              value: profile.populationGroup,
            },
            {
              key: 'selfDefinedEthnicity',
              label: 'Self-defined Ethnicity',
              value: profile.selfDefinedEthnicity,
            },
            {
              key: 'department',
              label: 'Department of Enrolment',
              value: profile.departmentOfEnrolment,
            },
            {
              key: 'time',
              label: 'Enrolment time',
              value: profile.enrolmentTime,
              format: 'friendlyDateTime',
            },
          ]}
        />
      </Box>
      <Box mb={4}>
        <Heading2 color="textPrimary" icon={BsPerson}>
          {t('participantEnrolment.staffTitle')}
        </Heading2>
        <SummaryTable
          rows={[
            {
              key: 'sFirstName',
              label: 'First Name',
              value: profile.staffMemberFirstName,
            },
            {
              key: 'sSurname',
              label: 'Surname',
              value: profile.staffMemberSurname,
            },
          ]}
        />
      </Box>
      {profile.witnessFirstName && profile.witnessSurname && (
        <Box mb={4}>
          <Heading2 color="textPrimary" icon={BsEye}>
            {t('participantEnrolment.witnessTitle')}
          </Heading2>
          <SummaryTable
            rows={[
              {
                key: 'wFirstName',
                label: 'First Name',
                value: profile.witnessFirstName,
              },
              {
                key: 'wSurname',
                label: 'Surname',
                value: profile.witnessSurname,
              },
            ]}
          />
        </Box>
      )}
      <Box mb={4}>
        <Heading2 color="textPrimary" icon={BsCheck2Square}>
          {t('participantEnrolment.consentTitle')}
        </Heading2>
        <SummaryTable
          rows={[
            {
              key: 'healthDataOptin',
              label: 'Health Data',
              value: 'Yes',
            },
            {
              key: 'consent1',
              label: 'Consent 1',
              value: 'Yes',
            },
            {
              key: 'consent2',
              label: 'Consent 2',
              value: 'Yes',
            },
            {
              key: 'consent3',
              label: 'Consent 3',
              value: 'Yes',
            },
            {
              key: 'consent4',
              label: 'Consent 4',
              value: 'Yes',
            },
            {
              key: 'consent5',
              label: 'Consent 5',
              value: 'Yes',
            },
            {
              key: 'consent6',
              label: 'Consent 6',
              value: 'Yes',
            },
            {
              key: 'consent7',
              label: 'Consent 7',
              value: 'Yes',
            },
            {
              key: 'consent8',
              label: 'Consent 8',
              value: 'Yes',
            },
          ]}
        />
      </Box>
    </ContentCard>
  );
}

export default EnrolmentBlock;

import moment from 'moment';
import { DateSelectionInputs } from '../../../types/monitoring.types';

const dateRange = (dateselection: DateSelectionInputs) => {
  const startOfWeek = moment(dateselection.fromDate);
  const endOfWeek = moment(dateselection.toDate);

  const days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.format('YYYY-MM-DD'));
    day = day.clone().add(1, 'd');
  }

  return days;
};

export const enrolmentMapper = (dateSelection: DateSelectionInputs, data: any) => {
  const dayRange = dateRange(dateSelection);

  const result = data.reduce((prev: any, row: any) => {
    return {
      ...prev,
      [row.dt]: {
        ...prev[row.dt],
        [row.sex]: row.numEnrolments,
        dt: row.dt,
      },
    };
  }, {});
  const fullRange = dayRange.map((day) => {
    return {
      dt: moment(day).format('DD MMM'),
      Male: result[day]?.Male || 0,
      Female: result[day]?.Female || 0,
      Intersex: result[day]?.Intersex || 0,
    };
  });
  return fullRange as any;
};

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import BarChartIcon from '@mui/icons-material/BarChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useTranslation } from 'react-i18next';
import { fetchChartThunk, chartStateForKeySelector, filtersSelector, setFilters } from '../../../../store/monitoring';
import { fetchTotalsThunk, totalsSelector, isBusySelector } from '../../../../store/totals';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { EXTRA_CHART_COLOURS } from '../../../../core/constants/theme.constant';
import InnerLayout from '../../../../layout/Inner';
import ReportLookup from '../../../../components/content/ReportLookup';
import Heading1 from '../../../../components/text/Heading1';
import HorizontalBarContainer from './components/HorizontalBarContainer';
import PieChartContainer from './components/PieChartContainer';
import VerticalBarContainer from './components/VerticalBarContainer';
import MonitoringReport from './components/MonitoringReport';
import { useStyles } from './component.styles';

function MonitoringScene() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const { classes } = useStyles();

  const { t } = useTranslation('portal');
  const printContainerRef = useRef<HTMLDivElement>(null);
  const theme: any = useTheme();
  const COLOURS = [theme.palette.primary.main, theme.palette.secondary.main, ...EXTRA_CHART_COLOURS];
  const dispatch = useAppDispatch();
  const filters = useAppSelector(filtersSelector);
  const isTotalsBusy = useAppSelector(isBusySelector);

  const friendlyDateRange = `${moment(filters.fromDate).format(
    "DD MMM 'YY",
  )} - ${moment(filters.toDate).format("DD MMM 'YY")}`;

  const enrolmentChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'enrolment');
  });

  const withdrawalChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'withdrawal');
  });

  const ageChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'age');
  });

  const departmentChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'department');
  });

  const sexChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'sex');
  });

  const populationGroupChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'populationGroup');
  });

  const signaturesChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'signatures');
  });

  const ehrChart = useAppSelector((state) => {
    return chartStateForKeySelector(state, 'ehr');
  });

  const totals = useAppSelector(totalsSelector);

  const handleFilter = (values: any) => {
    dispatch(setFilters(values));
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(fetchTotalsThunk(filters));
    dispatch(
      fetchChartThunk({
        chartType: 'enrolment',
        dateSelection: filters,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'withdrawal',
        dateSelection: filters,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'age',
        dateSelection: filters,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'department',
        dateSelection: filters,
        numDepts: 10,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'sex',
        dateSelection: filters,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'populationGroup',
        dateSelection: filters,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'signatures',
        dateSelection: filters,
      }),
    );
    dispatch(
      fetchChartThunk({
        chartType: 'ehr',
        dateSelection: filters,
      }),
    );
  }, [dispatch, filters]);

  return (
    <InnerLayout title="Site" title2="Portal">
      <Heading1
        icon={BarChartIcon}
        menu={
          <>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <ReactToPrint
                content={() => printContainerRef.current}
                onAfterPrint={() => {
                  setIsPrinting(false);
                }}
              >
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <MenuItem
                      onClick={() => {
                        setIsPrinting(true);
                        setTimeout(() => {
                          handlePrint();
                          handleClose();
                        }, 100);
                      }}
                    >
                      <PictureAsPdfOutlinedIcon color="action" style={{ marginRight: 5 }} />
                      {t('monitoring.printPDF')}
                    </MenuItem>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </Menu>
          </>
        }
      >
        {t('monitoring.title')}
      </Heading1>
      {filters && (
        <>
          <Box mb={4}>
            <ReportLookup onFilter={handleFilter} initialValues={filters} />
          </Box>

          <div ref={printContainerRef} className={classes.printContainer}>
            {totals && (
              <Box mb={4}>
                <MonitoringReport
                  title="Monitoring Report"
                  subtitle={friendlyDateRange}
                  totals={totals}
                  isBusy={isTotalsBusy}
                />
              </Box>
            )}

            <Box mb={4} data-testid="enrolments-chart">
              <VerticalBarContainer
                isPrinting={isPrinting}
                title="Daily Enrolments"
                subtitle={friendlyDateRange}
                height={400}
                colours={COLOURS}
                xAxisLabel="Date"
                yAxisLabel="Enrolments"
                isBusy={enrolmentChart.isBusy}
                data={enrolmentChart.data}
                dataKey="dt"
                bars={[
                  { dataKey: 'Male', name: 'Male' },
                  { dataKey: 'Female', name: 'Female' },
                  { dataKey: 'Intersex', name: 'Intersex' },
                ]}
              />
            </Box>
            <div className={classes.pageBreak} />
            <Box mb={4} data-testid="withdrawals-chart">
              <VerticalBarContainer
                isPrinting={isPrinting}
                data={withdrawalChart.data}
                dataKey="dt"
                bars={[
                  { dataKey: 'Male', name: 'Male' },
                  { dataKey: 'Female', name: 'Female' },
                  { dataKey: 'Intersex', name: 'Intersex' },
                ]}
                title="Daily Withdrawals"
                subtitle={friendlyDateRange}
                height={400}
                colours={COLOURS}
                xAxisLabel="Date"
                yAxisLabel="Withdrawals"
                isBusy={withdrawalChart.isBusy}
              />
            </Box>
            <div className={classes.pageBreak} />
            <Box mb={4} data-testid="age-chart">
              <VerticalBarContainer
                isPrinting={isPrinting}
                bars={[
                  { dataKey: 'Male', name: 'Male' },
                  { dataKey: 'Female', name: 'Female' },
                  { dataKey: 'Intersex', name: 'Intersex' },
                ]}
                height={400}
                colours={COLOURS}
                data={ageChart.data}
                dataKey="ageCategory"
                isBusy={ageChart.isBusy}
                subtitle={friendlyDateRange}
                title="Age Group Distribution"
                xAxisLabel="Age Groups"
                yAxisLabel="Enrolments"
              />
            </Box>
            <div className={classes.pageBreak} />
            <Box mb={4} data-testid="department-chart">
              <Grid container spacing={2}>
                <Grid item xs sm={12}>
                  <HorizontalBarContainer
                    isPrinting={isPrinting}
                    colours={COLOURS}
                    data={departmentChart.data}
                    dataKey="departmentOfEnrolment"
                    bars={[
                      {
                        dataKey: 'Male',
                        name: 'Male',
                      },
                      {
                        dataKey: 'Female',
                        name: 'Female',
                      },
                      {
                        dataKey: 'Intersex',
                        name: 'Intersex',
                      },
                    ]}
                    height={400}
                    isBusy={departmentChart.isBusy}
                    subtitle={friendlyDateRange}
                    title="Department of Enrolment"
                    xAxisLabel="Enrolments"
                    yAxisLabel="Department"
                  />
                </Grid>
              </Grid>
            </Box>
            <div className={classes.pageBreak} />
            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item xs sm={6} data-testid="gender-chart">
                  <PieChartContainer
                    isPrinting={isPrinting}
                    colours={COLOURS}
                    data={sexChart.data}
                    dataKey="numEnrolments"
                    height={400}
                    isBusy={sexChart.isBusy}
                    labelKey="sex"
                    subtitle={friendlyDateRange}
                    tooltipLabel="Enrolments"
                    title="Gender"
                  />
                </Grid>
                <Grid item xs sm={6} data-testid="population-chart">
                  <PieChartContainer
                    isPrinting={isPrinting}
                    colours={COLOURS}
                    data={populationGroupChart.data}
                    dataKey="numEnrolments"
                    height={400}
                    isBusy={populationGroupChart.isBusy}
                    labelKey="populationGroup"
                    subtitle={friendlyDateRange}
                    tooltipLabel="Enrolments"
                    title="Population Group"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs sm={6} data-testid="signature-method-chart">
                  <PieChartContainer
                    isPrinting={isPrinting}
                    colours={COLOURS}
                    data={signaturesChart.data}
                    dataKey="numEnrolments"
                    height={400}
                    isBusy={signaturesChart.isBusy}
                    labelKey="signatureMethod"
                    subtitle={friendlyDateRange}
                    tooltipLabel="Enrolments"
                    title="Signature Method"
                  />
                </Grid>
                <Grid item xs sm={6} data-testid="ehr-chart">
                  <PieChartContainer
                    isPrinting={isPrinting}
                    colours={COLOURS}
                    data={ehrChart.data}
                    dataKey="numEnrolments"
                    height={400}
                    isBusy={ehrChart.isBusy}
                    labelKey="EHROptIn"
                    subtitle={friendlyDateRange}
                    title="EHR Opt-in"
                    tooltipLabel="Enrolments"
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}
    </InnerLayout>
  );
}

export default MonitoringScene;

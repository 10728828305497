import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import ChartContainer, { DataView } from '../ChartContainer';

export interface MonitoringReportProps {
  totals: any;
  title: string;
  subtitle: string;
  isBusy?: boolean;
  loadingText?: string;
}

function MonitoringReport({ totals, title, subtitle, isBusy = false, loadingText }: MonitoringReportProps) {
  const informedConsentRows = totals
    ? [
        {
          label: 'Date report generated/checks made',
          value: moment().format('D MMM YYYY HH:mm'),
        },
        {
          label: 'Number of participants enrolled in the study during time period',
          value: totals.enrolmentsInPeriod,
        },
        {
          label: 'Proportion (n)  of participants withdrawn from the study',
          value: `${totals.enrolmentsInPeriod - totals.enrolmentsCurrentlyEnrolled} (${_.round(
            (totals.enrolmentsInPeriod - totals.enrolmentsCurrentlyEnrolled / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label:
            'Proportion (number) of participants who watched the enrolment video (as completed by site staff member)',
          value: `${totals.enrolmentsWatchedVideo} (${_.round(
            (totals.enrolmentsWatchedVideo / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label:
            'Proportion (number) of participants who read the Patient Information Sheet (as completed by site staff member)',
          value: `${totals.enrolmentsReadPIS} (${_.round(
            (totals.enrolmentsReadPIS / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of pts that agreed to all consent clauses',
          value: `${totals.enrolmentsWithAll8Consents} (${_.round(
            (totals.enrolmentsWithAll8Consents / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of participants who made a choice to receive their health information or not',
          value: `${totals.enrolmentsHealthOptionsSpecified} (${_.round(
            (totals.enrolmentsHealthOptionsSpecified / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of participant consent forms with a completed site staff signature',
          value: `${totals.enrolmentsWithValidStaffSignature} (${_.round(
            (totals.enrolmentsWithValidStaffSignature / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of participant consent forms signed with a written signature or fingerprint',
          value: `${totals.enrolmentsWithValidParticipantSignatureOrFingerprint} (${_.round(
            (totals.enrolmentsWithValidParticipantSignatureOrFingerprint / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label:
            'Proportion (number) of participant consent forms signed with a fingerprint which also have a witness signature',
          value: `${totals.enrolmentsWithValidWitnessSignatureAndFingerprint} (${_.round(
            (totals.enrolmentsWithValidWitnessSignatureAndFingerprint / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
      ]
    : [];

  const eligibilityCheckRows = totals
    ? [
        {
          label: 'Number of participants aged 18 years or above (as determined from Date of Birth collected in eCRF)',
          value: `${totals.enrolmentsOver18AtEnrolment} (${_.round(
            (totals.enrolmentsOver18AtEnrolment / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Number of participants with a unique IALCH ID',
          value: `${totals.enrolmentsUniqueIalchId} (${_.round(
            (totals.enrolmentsUniqueIalchId / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
      ]
    : [];

  const sampleCheckRows = totals
    ? [
        {
          label: 'Proportion (number) of participants with valid data for all 3 samples',
          value: `${totals.enrolmentsWithSampleStatusAndIdFor3Samples} (${_.round(
            (totals.enrolmentsWithSampleStatusAndIdFor3Samples / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of serum samples with sufficient status',
          value: `${totals.enrolmentsSerumSufficientSample} (${_.round(
            (totals.enrolmentsSerumSufficientSample / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of serum samples with insufficient status',
          value: `${totals.enrolmentsSerumInsufficientSample} (${_.round(
            (totals.enrolmentsSerumInsufficientSample / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of serum samples with no blood status',
          value: `${totals.enrolmentsSerumNoBlood} (${_.round(
            (totals.enrolmentsSerumNoBlood / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },

        {
          label: 'Proportion (number) of whole blood samples with sufficient status',
          value: `${totals.enrolmentsWholeBloodSufficientSample} (${_.round(
            (totals.enrolmentsWholeBloodSufficientSample / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of whole blood samples with insufficient status',
          value: `${totals.enrolmentsWholeBloodInsufficientSample} (${_.round(
            (totals.enrolmentsWholeBloodInsufficientSample / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of whole blood samples with no blood status',
          value: `${totals.enrolmentsWholeBloodNoBlood} (${_.round(
            (totals.enrolmentsWholeBloodNoBlood / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },

        {
          label: 'Proportion (number) of plasma samples with sufficient status',
          value: `${totals.enrolmentsPlasmaSufficientSample} (${_.round(
            (totals.enrolmentsPlasmaSufficientSample / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of plasma samples with insufficient status',
          value: `${totals.enrolmentsPlasmaInsufficientSample} (${_.round(
            (totals.enrolmentsPlasmaInsufficientSample / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
        {
          label: 'Proportion (number) of plasma samples with no blood status',
          value: `${totals.enrolmentsPlasmaNoBlood} (${_.round(
            (totals.enrolmentsPlasmaNoBlood / totals.enrolmentsInPeriod) * 100,
            2,
          )}%)`,
        },
      ]
    : [];

  return (
    <>
      <ChartContainer
        title={title}
        subtitle={subtitle}
        dataViews={[DataView.TABLE]}
        isBusy={isBusy}
        loadingText={loadingText}
      >
        {() => (
          <>
            <Box mb={2}>
              <Typography variant="h6" component="h4" align="left" color="textSecondary">
                Informed consent checks
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label={'Monitoring report'}>
                  <TableBody>
                    {informedConsentRows.map((row) => (
                      <TableRow key={row.label}>
                        <TableCell align="left">{row.label}</TableCell>
                        <TableCell align="right" width={200}>
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box mb={2}>
              <Typography variant="h6" component="h4" align="left" color="textSecondary">
                Participant eligibility checks
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label={'Eligibility report'}>
                  <TableBody>
                    {eligibilityCheckRows.map((row) => (
                      <TableRow key={row.label}>
                        <TableCell align="left">{row.label}</TableCell>
                        <TableCell align="right" width={200}>
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box mb={2}>
              <Typography variant="h6" component="h4" align="left" color="textSecondary">
                Sample collection form check
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label={'Samples report'}>
                  <TableBody>
                    {sampleCheckRows.map((row) => (
                      <TableRow key={row.label}>
                        <TableCell align="left">{row.label}</TableCell>
                        <TableCell align="right" width={200}>
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </ChartContainer>
    </>
  );
}

export default MonitoringReport;

import React from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { RiLockPasswordLine } from 'react-icons/ri';
import { ButtonRow, FormInput, Loader, useAuth, AuthLayout } from '@omnigenbiodata/ui';
import ROUTES from '../../../../core/constants/routes.constant';
import { PASSWORD_REGEX } from '../../../../core/constants/forms.constant';
import { useTranslation } from 'react-i18next';

function SubmitResetPassword() {
  const { t } = useTranslation(['portal', 'validation']);
  const navigate = useNavigate();

  const {
    forgotPasswordSubmitHasError,
    isBusy,
    forgotPasswordEmail,
    forgotPasswordSubmitHasSuccess,
    forgotPasswordSubmit,
    forgotPasswordDeliveryDetails,
    reset,
  } = useAuth();

  const validationSchema = yup.object().shape({
    code: yup.string().required(t('emailRequired', { ns: 'validation' })),
    password: yup
      .string()
      .required(t('passwordRequired', { ns: 'validation' }))
      .matches(PASSWORD_REGEX, t('passwordFormat', { ns: 'validation' })),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('passwordNoMatch', { ns: 'validation' }))
      .required(t('passwordConfirmRequired', { ns: 'validation' })),
  });

  const formik = useFormik({
    initialValues: {
      email: forgotPasswordEmail,
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      forgotPasswordSubmit(values);
    },
  });

  const handleCancel = () => {
    reset(); //TODO add to context
    navigate(ROUTES.auth);
  };

  return (
    <>
      {!forgotPasswordEmail && <Navigate to={ROUTES.authResetPassword} />}
      {forgotPasswordSubmitHasSuccess && <Navigate to={ROUTES.auth} />}
      <AuthLayout
        title={t('submitResetPassword.title')}
        text={
          forgotPasswordDeliveryDetails &&
          (forgotPasswordDeliveryDetails.DeliveryMedium === 'EMAIL'
            ? t('submitResetPassword.email', {
                destination: forgotPasswordDeliveryDetails.Destination,
              })
            : t('submitResetPassword.mobile', {
                destination: forgotPasswordDeliveryDetails.Destination,
              }))
        }
        text2={t('submitResetPassword.line2')}
        hasError={forgotPasswordSubmitHasError}
        errorText={t('alerts.recoveryFailed.text')}
        errorTitle={t('alerts.recoveryFailed.title')}
        dmsa
      >
        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <FormInput
              error={formik.errors.code}
              name="code"
              label={t('forms.code')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.code}
              value={formik.values.code}
            />
            <FormInput
              error={formik.errors.password}
              name="password"
              label={t('forms.newPassword')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password}
              value={formik.values.password}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
            <FormInput
              error={formik.errors.confirmPassword}
              name="confirmPassword"
              label={t('forms.confirmPassword')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.confirmPassword}
              value={formik.values.confirmPassword}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
          </Box>
          <ButtonRow
            forwardColor="primary"
            forwardLabel={t('buttons.confirm')}
            backLabel={t('buttons.cancel') as string}
            buttonSize="large"
            onBack={handleCancel}
          />
        </form>
        <Loader isVisible={isBusy} label={t('loaders.check') as string} />
      </AuthLayout>
    </>
  );
}

export default SubmitResetPassword;

import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { RiLockPasswordLine } from 'react-icons/ri';
import { ButtonRow, FormInput, Loader, AuthLayout } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@omnigenbiodata/ui';
import { PASSWORD_REGEX } from '../../../../core/constants/forms.constant';

function NewPassword() {
  const { t } = useTranslation(['portal', 'validation']);
  const { hasError, isBusy, completeNewPassword } = useAuth();

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('firstNameRequired', { ns: 'validation' })),
    lastName: yup.string().required(t('lastNameRequired', { ns: 'validation' })),
    password: yup
      .string()
      .required(t('passwordRequired', { ns: 'validation' }))
      .matches(PASSWORD_REGEX, t('passwordFormat', { ns: 'validation' })),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('passwordNoMatch', { ns: 'validation' }))
      .required(t('passwordConfirmRequired', { ns: 'validation' })),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      completeNewPassword(values);
    },
  });

  return (
    <AuthLayout
      title={t('newPassword.title')}
      text={t('newPassword.text')}
      hasError={hasError}
      errorText={t('alerts.regFailed.text')}
      errorTitle={t('alerts.regFailed.title')}
      dmsa
    >
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <FormInput
            error={formik.errors.firstName}
            name="firstName"
            label={t('forms.firstName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            value={formik.values.firstName}
          />
          <FormInput
            error={formik.errors.lastName}
            name="lastName"
            label={t('forms.lastName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.lastName}
            value={formik.values.lastName}
          />
          <FormInput
            error={formik.errors.password}
            name="password"
            label={t('forms.newPassword')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            value={formik.values.password}
            type="password"
            startAdornment={<RiLockPasswordLine />}
          />
          <FormInput
            error={formik.errors.confirmPassword}
            name="confirmPassword"
            label={t('forms.confirmPassword')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.confirmPassword}
            value={formik.values.confirmPassword}
            type="password"
            startAdornment={<RiLockPasswordLine />}
          />
        </Box>
        <ButtonRow forwardColor="primary" forwardLabel={t('buttons.confirm')} buttonSize="large" />
      </form>
      <Loader isVisible={isBusy} label={t('loaders.check') as string} />
    </AuthLayout>
  );
}

export default NewPassword;

import React from 'react';

import { BarChart, Bar, Tooltip, ResponsiveContainer, Legend, XAxis, YAxis, CartesianGrid, Label } from 'recharts';
import useTheme from '@mui/material/styles/useTheme';
import TableData from '../../../../../../components/content/TableData';
import ChartContainer, { DataView } from '../ChartContainer';
import { useStyles } from './component.styles';

export interface ChartBar {
  dataKey: string;
  name?: string;
}

export interface VerticalBarContainerProps {
  bars: ChartBar[];
  colours: string[];
  data: any[];
  title: string;
  subtitle?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  height?: number;
  isBusy?: boolean;
  loadingText?: string;
  dataKey?: string;
  isPrinting?: boolean;
}

function VerticalBarContainer({
  bars,
  colours,
  data,
  title,
  subtitle,
  xAxisLabel,
  yAxisLabel,
  height = 400,
  isBusy = false,
  loadingText = 'Loading',
  dataKey = 'bucket',
  isPrinting,
}: VerticalBarContainerProps) {
  const { classes } = useStyles();
  const theme: any = useTheme();

  return (
    <ChartContainer title={title} subtitle={subtitle} isBusy={isBusy} loadingText={loadingText}>
      {(chartType) => (
        <>
          {(chartType === DataView.CHART || isPrinting) && (
            <div style={{ height }} className={classes.chartContainer}>
              {data && (
                <ResponsiveContainer width="100%" height={height}>
                  <BarChart height={height} data={data}>
                    <CartesianGrid stroke={theme.palette?.grey[300]} strokeDasharray="3 3" />
                    <XAxis
                      dataKey={dataKey}
                      label={
                        xAxisLabel
                          ? {
                              value: xAxisLabel,
                              position: 'insideBottomCenter',
                              dy: 20,
                            }
                          : undefined
                      }
                      scale="band"
                      type="category"
                    />
                    <YAxis allowDecimals={false} domain={[0, 'dataMax+1']} interval={1} type="number">
                      {yAxisLabel && (
                        <Label angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} value={yAxisLabel} />
                      )}
                    </YAxis>
                    <Tooltip />
                    <Legend height={50} wrapperStyle={{ paddingTop: 20 }} />
                    {bars.map((bar: ChartBar, index: number) => (
                      <Bar
                        dataKey={bar.dataKey}
                        fill={colours[index]}
                        key={`cell-${index}`}
                        name={bar.name}
                        stackId={'a'}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          )}
          {data && (chartType === DataView.TABLE || isPrinting) && (
            <TableData
              title={`${title} tabular data`}
              data={data}
              columns={[{ dataKey, name: xAxisLabel, align: 'left' }, ...bars]}
            />
          )}
        </>
      )}
    </ChartContainer>
  );
}

export default VerticalBarContainer;

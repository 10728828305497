import moment from 'moment';
import luhn from 'fast-luhn';
import { Gender } from '../../types/common.types';

export const isOneOf = (value: string | number | null | undefined, haystack: string[] | number[]) => {
  if (!value) {
    return false;
  }
  return haystack && value ? haystack.includes(value as never) : false;
};

export const isInRange = (value: string | null | undefined | number, min: number, max: number) => {
  if (!value) {
    return false;
  }
  const intValue = typeof value !== 'number' ? parseInt(value) : value;
  return intValue >= min && intValue <= max;
};

export const isLength = (value: string, length: number) => {
  return value.length === length;
};

export const dateToIdFormat = (dateString: string | undefined | null) => {
  const formattedDateString = dateString ? moment(dateString).format('YYMMDD') : '';
  return formattedDateString;
};

export const splitNationaID = (value: string | null | undefined) => {
  const dobPart = value?.substr(0, 6);
  const sexPart = value?.substr(6, 4);
  const citizenPart = value?.substr(10, 1);
  const extraPart = value?.substr(11, 1);
  const checkPart = value?.substr(12, 1);

  return [dobPart, sexPart, citizenPart, extraPart, checkPart];
};

export const nationalID = (value: string | null | undefined, dob: string, sex: Gender) => {
  if (!value) {
    return false;
  }
  // National ID is 13 digits in length
  // [DATE PART, 0-5] [SEX PART, 6-9] [CITIZEN PART, 10] [EXTRA PART, 11] [CHECK PART, 12]

  let dobPartValid = false;
  let sexPartValid = false;
  let citizenPartValid = false;
  let checkPartValid = true;

  const idParts = splitNationaID(value);
  const formattedDob = dateToIdFormat(dob);

  // Check supplied date of birth matches dob identifier
  dobPartValid = idParts[0] === formattedDob;

  // Check supplied sex matches gender identifier
  sexPartValid =
    isLength(idParts[1] || '', 4) &&
    ((sex === Gender.MALE && isInRange(idParts[1], 5000, 9999)) ||
      (sex === Gender.FEMALE && isInRange(idParts[1], 0, 4999)) ||
      sex === Gender.INTERSEX);

  // Check supplied citizenship digit matches identifier
  citizenPartValid = isLength(idParts[2] || '', 1) && isOneOf(idParts[2], ['0', '1']);

  // Check supplied checksum matches luhn validation
  checkPartValid = idParts[4] ? luhn(value) : false;

  console.log(
    idParts[0],
    formattedDob,
    dobPartValid,
    sexPartValid,
    citizenPartValid,
    checkPartValid,
    idParts[4],
    luhn(value),
  );

  return dobPartValid && sexPartValid && citizenPartValid && checkPartValid;
};
const nationalIdHelper = { nationalID };

export default nationalIdHelper;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constant';
import { useAppSelector } from '../../store';
import { userProfileSelector } from '../../store/user';

function PoertalScene() {
  const profile = useAppSelector(userProfileSelector);

  if (!profile) {
    return <Navigate to={ROUTES.splash} />;
  }

  return <Outlet />;
}

export default PoertalScene;

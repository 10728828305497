import { RootState } from '../index';
import { KeyValues, StoreStatus } from '../../core/types/common.types';
import { ParticipantProfile, WithdrawParticipantInput } from '../../core/types/participants.types';

export const hasLookupErrorSelector = (state: RootState): boolean =>
  state.participant.errors && state.participant.errors.length > 0 ? true : false;

export const lookupErrorTypeSelector = (state: RootState): string[] =>
  state.participant.errors ? state.participant.errors.map((error) => error.errorType) : [];

export const hasLookupSuccessSelector = (state: RootState): boolean => (state.participant.result?.id ? true : false);

export const participantIdSelector = (state: RootState): string | null =>
  state.participant.result?.id ? state.participant.result?.id : null;

export const participantProfileSelector = (state: RootState): ParticipantProfile | null =>
  state.participant.result ? state.participant.result : null;

export const formValueSelector = (state: RootState): KeyValues => state.participant.values || {};

export const isBusySelector = (state: RootState): boolean => state.participant.status === StoreStatus.BUSY;

export const withdrawalValuesSelector = (state: RootState): WithdrawParticipantInput | null =>
  state.participant.withdrawalValues;

export const hasWithdrawalErrorSelector = (state: RootState): any | null =>
  state.participant.withdrawalError ? true : false;

export const withdrawalErrorSelector = (state: RootState): any | null => state.participant.withdrawalError;

export const withdrawalResultSelector = (state: RootState): WithdrawParticipantInput | null =>
  state.participant.withdrawalResult;

export const isWithdrawalBusySelector = (state: RootState): boolean =>
  state.participant.withdrawalStatus === StoreStatus.BUSY;

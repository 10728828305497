import React from 'react';
import TabPanel from '@mui/lab/TabPanel';
import EmailForm from './EmailForm'; // TODO: Make visible once we can edit protected attributes
import MobileForm from './MobileForm'; // TODO: Make visible once we can edit protected attributes
import ProfileForm from './ProfileForm';
import WithdrawalBlock from './WithdrawalBlock';
import { ParticipantProfile } from '../../../../../../../../../../core/types/participants.types';

export interface ProfileTabProps {
  profile: ParticipantProfile;
  onSave: (values: any) => void;
  onSaveEmail: (values: any) => void;
  onSaveMobile: (values: any) => void;
}

function ProfileTab({ profile, onSave }: ProfileTabProps) {
  return (
    <TabPanel value="1" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <ProfileForm profile={profile} onSubmit={onSave} />
      <EmailForm email={profile?.email} onSubmit={() => {}} />
      <MobileForm mobile={profile?.mobile} onSubmit={() => {}} />
      <WithdrawalBlock hospitalNo={profile.hospitalNo} withdrawalOption={profile.withdrawalOption} />
    </TabPanel>
  );
}

export default ProfileTab;

export const response = {
  data: {
    reportDailyEnrolmentsWithdrawals: [
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-01',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Intersex',
        dt: '2022-01-01',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-01',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-02',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-02',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-03',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-03',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-04',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-04',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-05',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-06',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-07',
      },
      {
        numEnrolments: 4,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-07',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Intersex',
        dt: '2022-01-08',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-08',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-10',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-11',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 1,
        sex: 'Female',
        dt: '2022-01-12',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-13',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-13',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 1,
        sex: 'Female',
        dt: '2022-01-14',
      },
      {
        numEnrolments: 0,
        numWithdrawals: 1,
        sex: 'Intersex',
        dt: '2022-01-14',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-14',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-15',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-15',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-16',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-16',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-17',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-18',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-18',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-19',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Intersex',
        dt: '2022-01-19',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-20',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Intersex',
        dt: '2022-01-21',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-21',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-22',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-23',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 1,
        sex: 'Male',
        dt: '2022-01-24',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-25',
      },
      {
        numEnrolments: 4,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-26',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 1,
        sex: 'Female',
        dt: '2022-01-27',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-27',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-28',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-29',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-30',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-30',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-01-31',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-01-31',
      },
      {
        numEnrolments: 4,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-01',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Intersex',
        dt: '2022-02-01',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-01',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 1,
        sex: 'Male',
        dt: '2022-02-02',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-03',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-03',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-04',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-04',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-05',
      },
      {
        numEnrolments: 4,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-05',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-06',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-07',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 1,
        sex: 'Female',
        dt: '2022-02-08',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-08',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-09',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-09',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-10',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-10',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-11',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-11',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-12',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-13',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-13',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-14',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-15',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Intersex',
        dt: '2022-02-15',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-15',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-16',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-16',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-18',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-18',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-19',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-20',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-20',
      },
      {
        numEnrolments: 1,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-21',
      },
      {
        numEnrolments: 3,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-21',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-22',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Female',
        dt: '2022-02-23',
      },
      {
        numEnrolments: 2,
        numWithdrawals: 0,
        sex: 'Male',
        dt: '2022-02-23',
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TuneIcon from '@mui/icons-material/Tune';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import * as yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';

function ReportLookup({ onFilter = () => undefined, initialValues = null }: any) {
  const validationSchema = yup.object().shape({
    fromDate: yup.string().required(),
    toDate: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onFilter(values);
    },
  });

  return (
    <Paper elevation={1}>
      <Box p={2}>
        {initialValues && (
          <form onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container justifyContent="space-around" alignItems="center">
                <TuneIcon fontSize="large" color="action" />
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  renderInput={(props) => (
                    <TextField
                      error={formik.errors.fromDate ? true : false}
                      variant="standard"
                      name="fromDate"
                      size="medium"
                      onBlur={formik.handleBlur}
                      id="date-picker-inline"
                      style={{ marginLeft: 10 }}
                      {...props}
                    />
                  )}
                  label="Date from"
                  disableFuture
                  onChange={(val: any) => {
                    formik.setFieldValue('fromDate', val?.format('YYYY-MM-DD'));
                  }}
                  value={formik.values.fromDate}
                />
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  renderInput={(props) => (
                    <TextField
                      error={formik.errors.toDate ? true : false}
                      variant="standard"
                      name="toDate"
                      onBlur={formik.handleBlur}
                      id="date-picker-inline"
                      style={{ marginRight: 'auto', marginLeft: 25 }}
                      {...props}
                    />
                  )}
                  label="Date to"
                  disableFuture
                  onChange={(val: any) => {
                    formik.setFieldValue('toDate', val?.format('YYYY-MM-DD'));
                  }}
                  value={formik.values.toDate}
                />
                <Button variant="outlined" size="large" type="submit" endIcon={<ChevronRightOutlinedIcon />}>
                  Report
                </Button>
              </Grid>
            </LocalizationProvider>
          </form>
        )}
      </Box>
    </Paper>
  );
}

export default ReportLookup;

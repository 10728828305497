import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import clsx from 'classnames';

export interface TableColum {
  dataKey: string;
  name?: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
}

export interface TableDataProps {
  data: any[];
  columns: TableColum[];
  title?: string;
}

const useStyles = makeStyles()((theme) => ({
  headerRow: { background: theme.palette?.grey[200] },
  oddRow: { background: theme.palette?.grey[100] },
  evenRow: { background: theme.palette.grey[50] },
}));

function TableData({ title, data, columns }: TableDataProps) {
  const { classes } = useStyles();
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label={title}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {columns.map((col) => (
                <TableCell key={`${title}-header-${col.name}`} align={col.align || 'right'}>
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={`${title}-${row.name}-${rowIndex}`}
                className={clsx({
                  [classes.oddRow]: rowIndex % 2 !== 0,
                  [classes.evenRow]: rowIndex % 2 === 0,
                })}
              >
                {columns.map((col) => (
                  <TableCell
                    key={`${title}-body-${col.name}-${row[col.dataKey]}-${rowIndex}`}
                    align={col.align || 'right'}
                  >
                    {row[col.dataKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableData;

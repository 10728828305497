import { createSlice } from '@reduxjs/toolkit';
import { StoreStatus, KeyValues } from '../../core/types/common.types';
import { getParticipantConsentFormLinkThunk } from './thunks';

export interface ConsentFormState {
  errors: any;
  result: any;
  status: StoreStatus;
  values: KeyValues | null;
}

// Initial State
export const initialState: ConsentFormState = {
  status: StoreStatus.IDLE,
  values: null,
  result: null,
  errors: null,
};

// Slice
export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    resetConsentForm: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantConsentFormLinkThunk.pending.type, (state: ConsentFormState) => {
      state.status = StoreStatus.BUSY;
      state.errors = null;
    });
    builder.addCase(getParticipantConsentFormLinkThunk.fulfilled.type, (state: ConsentFormState, action: any) => {
      state.result = action.payload;
      state.status = StoreStatus.IDLE;
    });
    builder.addCase(getParticipantConsentFormLinkThunk.rejected.type, (state: ConsentFormState, action: any) => {
      state.result = null;
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
    });
  },
});

// Action Creators
export * from './thunks';
export * from './selectors';
export const { resetConsentForm } = consentSlice.actions;
export default consentSlice.reducer;

import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import ROUTES from '../../../../../../core/constants/routes.constant';
import { participantProfileSelector } from '../../../../../../store/participant';
import { useAppSelector } from '../../../../../../store';

function ParticipantResultScene() {
  const { hospitalNo } = useParams<any>();
  const profile = useAppSelector(participantProfileSelector);

  if ((!profile && !hospitalNo) || profile?.hospitalNo !== hospitalNo) {
    return <Navigate to={ROUTES.participantLookup} />;
  }

  return <Outlet />;
}

export default ParticipantResultScene;

export const response = {
  data: {
    reportEnrolmentsByEHROptIn: [
      {
        EHROptIn: 'no',
        numEnrolments: 107,
      },
      {
        EHROptIn: 'yes',
        numEnrolments: 118,
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

import React from 'react';
import { ComposedChart, Bar, Tooltip, ResponsiveContainer, Legend, YAxis, XAxis, CartesianGrid, Label } from 'recharts';
import useTheme from '@mui/material/styles/useTheme';
import TableData from '../../../../../../components/content/TableData';
import ChartContainer, { DataView } from '../ChartContainer';
import { useStyles } from './component.styles';

export interface ChartBar {
  dataKey: string;
  name?: string;
}

export interface HorizontalBarContainerProps {
  title: string;
  subtitle?: string;
  data: any;
  bars: ChartBar[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  isBusy?: boolean;
  height?: number;
  loadingText?: string;
  colours: string[];
  dataKey?: string;
  isPrinting?: boolean;
}

function HorizontalBarContainer({
  bars,
  colours,
  data,
  dataKey = 'bucket',
  height = 400,
  isBusy = false,
  loadingText = 'Loading',
  subtitle,
  title,
  xAxisLabel,
  yAxisLabel,
  isPrinting,
}: HorizontalBarContainerProps) {
  const { classes } = useStyles();
  const theme: any = useTheme();

  return (
    <ChartContainer title={title} subtitle={subtitle} isBusy={isBusy} loadingText={loadingText}>
      {(chartType) => (
        <>
          {data && (chartType === DataView.CHART || isPrinting) && (
            <div style={{ height }} className={classes.chartContainer}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart layout="vertical" height={height} data={data}>
                  <CartesianGrid stroke={theme.palette?.grey[300]} strokeDasharray="3 3" />
                  <XAxis
                    allowDecimals={false}
                    label={
                      xAxisLabel
                        ? {
                            value: xAxisLabel,
                            position: 'insideBottomCenter',
                            dy: 20,
                          }
                        : undefined
                    }
                    type="number"
                  />

                  <YAxis dataKey={dataKey} type="category" scale="band" interval={0} width={200}>
                    {yAxisLabel && (
                      <Label angle={-90} value={yAxisLabel} position="insideLeft" className={classes.chartYAxisLabel} />
                    )}
                  </YAxis>
                  <Tooltip />
                  <Legend height={50} wrapperStyle={{ paddingTop: 20 }} />
                  {bars.map((bar: any, barIndex: number) => (
                    <Bar
                      key={`bar-${barIndex}`}
                      dataKey={bar.dataKey}
                      barSize={12}
                      fill={colours[barIndex]}
                      name={bar.name}
                      stackId="a"
                    />
                  ))}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}
          {data && (chartType === DataView.TABLE || isPrinting) && (
            <TableData
              title={`${title} tabular data`}
              data={data}
              columns={[{ dataKey: dataKey, name: yAxisLabel, align: 'left' }, ...bars]}
            />
          )}
        </>
      )}
    </ChartContainer>
  );
}

export default HorizontalBarContainer;

export const response = {
  data: {
    reportAgeGroupDistribution: [
      {
        ageCategory: '18-25',
        numEnrolments: 3,
        sex: 'Female',
      },
      {
        ageCategory: '25-30',
        numEnrolments: 9,
        sex: 'Female',
      },
      {
        ageCategory: '30-35',
        numEnrolments: 11,
        sex: 'Female',
      },
      {
        ageCategory: '35-40',
        numEnrolments: 6,
        sex: 'Female',
      },
      {
        ageCategory: '40-45',
        numEnrolments: 8,
        sex: 'Female',
      },
      {
        ageCategory: '45-50',
        numEnrolments: 6,
        sex: 'Female',
      },
      {
        ageCategory: '50-55',
        numEnrolments: 7,
        sex: 'Female',
      },
      {
        ageCategory: '55-60',
        numEnrolments: 3,
        sex: 'Female',
      },
      {
        ageCategory: '60-65',
        numEnrolments: 6,
        sex: 'Female',
      },
      {
        ageCategory: '65-70',
        numEnrolments: 8,
        sex: 'Female',
      },
      {
        ageCategory: '70-75',
        numEnrolments: 8,
        sex: 'Female',
      },
      {
        ageCategory: '75+',
        numEnrolments: 23,
        sex: 'Female',
      },
      {
        ageCategory: '18-25',
        numEnrolments: 2,
        sex: 'Intersex',
      },
      {
        ageCategory: '30-35',
        numEnrolments: 3,
        sex: 'Intersex',
      },
      {
        ageCategory: '40-45',
        numEnrolments: 2,
        sex: 'Intersex',
      },
      {
        ageCategory: '60-65',
        numEnrolments: 2,
        sex: 'Intersex',
      },
      {
        ageCategory: '65-70',
        numEnrolments: 1,
        sex: 'Intersex',
      },
      {
        ageCategory: '70-75',
        numEnrolments: 1,
        sex: 'Intersex',
      },
      {
        ageCategory: '75+',
        numEnrolments: 2,
        sex: 'Intersex',
      },
      {
        ageCategory: '18-25',
        numEnrolments: 7,
        sex: 'Male',
      },
      {
        ageCategory: '25-30',
        numEnrolments: 10,
        sex: 'Male',
      },
      {
        ageCategory: '30-35',
        numEnrolments: 6,
        sex: 'Male',
      },
      {
        ageCategory: '35-40',
        numEnrolments: 9,
        sex: 'Male',
      },
      {
        ageCategory: '40-45',
        numEnrolments: 10,
        sex: 'Male',
      },
      {
        ageCategory: '45-50',
        numEnrolments: 12,
        sex: 'Male',
      },
      {
        ageCategory: '50-55',
        numEnrolments: 6,
        sex: 'Male',
      },
      {
        ageCategory: '55-60',
        numEnrolments: 12,
        sex: 'Male',
      },
      {
        ageCategory: '60-65',
        numEnrolments: 8,
        sex: 'Male',
      },
      {
        ageCategory: '65-70',
        numEnrolments: 14,
        sex: 'Male',
      },
      {
        ageCategory: '70-75',
        numEnrolments: 4,
        sex: 'Male',
      },
      {
        ageCategory: '75+',
        numEnrolments: 16,
        sex: 'Male',
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

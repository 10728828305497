import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  '@global': {
    '.recharts-wrapper, .recharts-surface': {
      '@media print': {
        width: 'calc(100%) !important',
      },
    },
  },
  printContainer: {
    '@media print': {
      padding: 40,
    },
  },
  pageBreak: {
    '@media print': {
      pageBreakBefore: 'always',
      marginBottom: 30,
    },
  },
}));

import { createAsyncThunk } from '@reduxjs/toolkit';
import monitoringApi from '../../core/api/monitoring/monitoringApi';
import { SummaryResponse, SummaryRejectionResponse } from '../../core/types/summary.types';

export const fetchSummaryThunk = createAsyncThunk<SummaryResponse, undefined, SummaryRejectionResponse>(
  'summary/fetchSummary',
  async (params, { rejectWithValue }) => {
    try {
      return await monitoringApi.fetchSummary();
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  },
);

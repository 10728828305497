import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import Heading2 from '../../../../../../../../../../../components/text/Heading2';
import ROUTES, { URL_PARAMS } from '../../../../../../../../../../../core/constants/routes.constant';
import { WithdrawalOption } from '../../../../../../../../../../../core/types/participants.types';
import { useStyles } from './component.styles';

export interface WithdrawalBlockProps {
  hospitalNo: string;
  withdrawalOption: string | null;
}

function WithdrawalBlock({ hospitalNo, withdrawalOption }: WithdrawalBlockProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('portal');
  return (
    <Paper className={classes.paperDanger}>
      <Box p={4}>
        <Heading2 color="textPrimary" icon={MdOutlineCancel}>
          {t('participantWithdrawal.title')}
        </Heading2>

        {withdrawalOption && (
          <>
            <Typography variant="body1" component="p" paragraph>
              {t('participantWithdrawal.previously')}
            </Typography>
            <Typography variant="body1" component="span">
              <Markdown>{t(`${withdrawalOption}.label`, { ns: 'withdrawals' })}</Markdown>
            </Typography>
          </>
        )}
        {withdrawalOption !== WithdrawalOption.COMPLETE && (
          <>
            <Typography variant="body1" component="p" paragraph>
              {t('participantWithdrawal.para1')}
            </Typography>
            <Button
              variant="contained"
              color="error"
              size="large"
              data-testid="withdraw"
              component={Link}
              to={ROUTES.participantWithdrawal.replace(URL_PARAMS.hospitalNo, hospitalNo)}
            >
              {t('participantWithdrawal.button')}
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default WithdrawalBlock;

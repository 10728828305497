const response = {
  data: {
    reportHeadlineTotals: {
      distinctDepts: 65,
      totalEnrolments: 1000,
      totalWithdrawals: 57,
    },
  },
};

const resolver = (variables: any) => {
  return response;
};
export default resolver;

import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import TableData from '../../../../../../components/content/TableData';
import ChartContainer, { DataView } from '../ChartContainer';
import { useStyles } from './component.styles';

export interface PieChartContainerProps {
  title: string;
  subtitle?: string;
  data: any;
  isBusy?: boolean;
  height?: number;
  width?: number;
  loadingText?: string;
  colours: string[];
  dataKey: string;
  labelKey: string;
  tooltipLabel?: string;
  isPrinting?: boolean;
}

function PieChartContainer({
  colours,
  data,
  dataKey = 'value',
  labelKey = 'label',
  height = 400,
  isBusy = false,
  loadingText = 'Loading',
  subtitle,
  title,
  tooltipLabel = '',
  width = 500,
  isPrinting,
}: PieChartContainerProps) {
  const { classes } = useStyles();

  return (
    <ChartContainer
      title={title}
      subtitle={subtitle}
      isBusy={isBusy}
      loadingText={loadingText}
      dataViews={[DataView.DONUT, DataView.PIE, DataView.TABLE]}
    >
      {(dataView) => (
        <>
          {(dataView === DataView.PIE || dataView === DataView.DONUT || isPrinting) && (
            <div
              style={{
                height,
                maxWidth: width,
              }}
              className={classes.chartContainer}
            >
              {data && (
                <PieChart width={width} height={height} onMouseEnter={() => {}}>
                  <Pie
                    data={data}
                    cx={width / 2}
                    cy={height / 2}
                    isAnimationActive={false}
                    innerRadius={dataView === DataView.DONUT ? height * 0.22 : 0}
                    outerRadius={height * 0.3}
                    paddingAngle={2}
                    dataKey={dataKey}
                    label={(entry) => {
                      return entry[labelKey] || null;
                    }}
                    name={tooltipLabel}
                  >
                    {data.map((entry: any, index: number) => (
                      <Cell key={`cell-${index}`} fill={colours[index % colours.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              )}
            </div>
          )}
          {data && (dataView === DataView.TABLE || isPrinting) && (
            <TableData
              title={`${title} tabular data`}
              data={data}
              columns={[
                { dataKey: labelKey, name: title, align: 'left' },
                { dataKey, name: tooltipLabel },
              ]}
            />
          )}
        </>
      )}
    </ChartContainer>
  );
}

export default PieChartContainer;

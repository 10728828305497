import React, { useEffect } from 'react';
import { Loader } from '@omnigenbiodata/ui';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  setupSelector,
  userProfileSelector,
  getMyStaffProfileThunk,
  acceptStaffLegalNoticesThunk,
  isBusySelector,
} from '../../store/user';
import ROUTES from '../../core/constants/routes.constant';
import CONTENT from '../../core/constants/content.constant';
import FEATURES from '../../core/constants/features.constant';

function SplashScene() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(userProfileSelector);
  const isBusy = useAppSelector(isBusySelector);
  const setupRequired = useAppSelector(setupSelector);

  useEffect(() => {
    dispatch(getMyStaffProfileThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!isBusy) {
      if (
        (setupRequired && FEATURES.acceptLegalNotices) ||
        (profile && profile?.termsAccepted !== CONTENT.CURRENT_TERMS_VERSION && FEATURES.acceptLegalNotices)
      ) {
        navigate(ROUTES.consentTerms);
      } else if (setupRequired && !FEATURES.acceptLegalNotices) {
        // Do setup here instead as legal notices are turned off
        dispatch(
          acceptStaffLegalNoticesThunk({
            termsAccepted: CONTENT.CURRENT_TERMS_VERSION,
            privacyAccepted: CONTENT.CURRENT_PRIVACY_VERSION,
          }),
        );
      } else if (profile) {
        navigate(ROUTES.root);
      }
    }
  }, [dispatch, profile, isBusy, setupRequired, navigate]);

  return setupRequired ? <Loader label="Creating Profile" isVisible /> : <Loader label="Loading Profile" isVisible />;
}

export default SplashScene;

import {
  BloodSampleType,
  Gender,
  PopulationGroup,
  LanguageName,
  LanguageCode,
  Department,
} from '../types/common.types';
import { SelectOption } from '@omnigenbiodata/ui';

/* Validation Rules */
export const DATE_VALUE_FORMAT = 'YYYY-MM-DD HH:mm';
export const HOSPTIAL_NUM_PREFIX = 'KZ';
export const MOBILE_NUMBER_REGEX = /^(\+27|\+44)[0-9]{9,10}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const POSTAL_CODE_REGEX = /^[0-9]{4}$/;
export const SAMPLE_BARCODE_LENGTH = 7;
export const SAMPLE_BARCODE_REGEX = /^[0-9]+$/;
export const TELEPHONE_NUMBER_REGEX = /^\+27[0-9]{9,10}$/;

/* Options / CodeLists */
export const BLOOD_STATUSES_VALID = [BloodSampleType.SUFFICIENT, BloodSampleType.INSUFFICIENT, BloodSampleType.NONE];
export const BLOOD_STATUSES_INVALID = [''];

export const BLOOD_STATUSES_ALL = [...BLOOD_STATUSES_VALID, ...BLOOD_STATUSES_INVALID].filter(
  (val: string) => val !== '',
);

export const DEPARTMENTS: Department[] = Object.values(Department);

export const DEPARTMENT_OPTIONS: SelectOption[] = DEPARTMENTS.map((department: Department) => ({
  label: department,
  value: department,
}));

export const LANGUAGE_OPTIONS: SelectOption[] = [
  { label: LanguageName.EN, value: LanguageCode.EN },
  { label: LanguageName.ZU, value: LanguageCode.ZU },
];

export const POPULATION_GROUP_OPTIONS: SelectOption[] = [
  { label: PopulationGroup.AFRICAN, value: PopulationGroup.AFRICAN },
  { label: PopulationGroup.COLOURED, value: PopulationGroup.COLOURED },
  { label: PopulationGroup.INDOASIAN, value: PopulationGroup.INDOASIAN },
  { label: PopulationGroup.WHITE, value: PopulationGroup.WHITE },
  { label: PopulationGroup.OTHER, value: PopulationGroup.OTHER },
];

export const SEX_OPTIONS: SelectOption[] = [
  { label: Gender.FEMALE, value: Gender.FEMALE },
  { label: Gender.INTERSEX, value: Gender.INTERSEX },
  { label: Gender.MALE, value: Gender.MALE },
];

/*  Steps */
export const ENROLMENT_STEPS = ['Information', 'Consent', 'Details', 'Review'];
export const SAMPLE_STEPS = ['Serum', 'Whole Blood', 'Plasma', 'Review'];
export const TOTAL_CONSENT_STEPS = 9;

const FORMS = {
  BLOOD_STATUSES_ALL,
  DATE_VALUE_FORMAT,
  HOSPTIAL_NUM_PREFIX,
  MOBILE_NUMBER_REGEX,
  PASSWORD_REGEX,
  ENROLMENT_STEPS,
  DEPARTMENTS,
  DEPARTMENT_OPTIONS,
  LANGUAGE_OPTIONS,
  POPULATION_GROUP_OPTIONS,
  SEX_OPTIONS,
  SAMPLE_STEPS,
};
export default FORMS;

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  appBar: {
    borderTopWidth: 8,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.main,
  },
  appBarHamburger: { marginRight: theme.spacing(1) },
  appBarDivider: {
    display: 'block',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderLeftWidth: 1,
    minHeight: 40,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[500],
  },
  appBarTitle: { flexGrow: 1 },
  appBarUser: { marginRight: theme.spacing(1) },
  appBarUserIconBtn: { marginRight: theme.spacing(1) },
  imgLogo: {
    display: 'block',
    maxHeight: 40,
  },
  layoutWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 72px)',
    paddingTop: 40,
  },
  layoutContent: {
    flexGrow: 1,
  },
  layoutFooter: { flexGrow: 0 },
}));

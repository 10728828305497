import React, { ReactNode } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

interface PortalPageProps {
  children?: ReactNode;
}

function PortalPage({ children }: PortalPageProps) {
  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default PortalPage;

import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

export interface ProfileSummaryProps {
  firstName?: string | null;
  lastName?: string | null;
  status?: string | null;
  statusColor?: 'primary' | 'secondary';
  sub?: string | null;
  deceased: boolean;
  dataHasArrived: boolean;
}

function ProfileSummary({
  firstName = null,
  lastName = null,
  status = null,
  statusColor = 'primary',
  sub = null,
  deceased = false,
  dataHasArrived = false,
}: ProfileSummaryProps) {
  return (
    <div style={{ display: 'flex', msFlexDirection: 'row' }}>
      <Avatar
        style={{
          width: 100,
          height: 100,
          fontSize: 50,
          marginRight: 10,
        }}
      >
        {firstName?.substring(0, 1)}
        {lastName?.substring(0, 1)}
      </Avatar>
      <div>
        {!deceased && status && (
          <Chip
            color={statusColor}
            label={status}
            style={{
              marginBottom: 5,
              textTransform: 'uppercase',
            }}
          />
        )}
        {deceased && (
          <Chip
            color="error"
            label={'Deceased'}
            style={{
              marginBottom: 5,
              textTransform: 'uppercase',
            }}
          />
        )}
        {dataHasArrived && (
          <Chip
            color="info"
            label={'EHR'}
            style={{
              marginBottom: 5,
              marginLeft: 5,
              textTransform: 'uppercase',
            }}
          />
        )}

        {firstName && lastName && (
          <Typography variant="h4" component="p" color="textSecondary">
            {firstName} {lastName}
          </Typography>
        )}
        {sub && (
          <Typography variant="body1" component="p" gutterBottom color="textSecondary">
            {sub}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ProfileSummary;

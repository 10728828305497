import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { PageWrapper, PartnerStrip, DMSALogo, Hidden } from '@omnigenbiodata/ui';

import { useStyles } from './component.styles';
import { PARTNER_LOGOS } from '../../core/constants/content.constant';
import ANIMATIONS from '../../core/constants/animations.constant';

interface MainLayoutProps {
  children: ReactNode;
  title?: string;
}

function MainLayout({ children, title = '' }: MainLayoutProps) {
  const { classes } = useStyles();
  return (
    <PageWrapper isShaded>
      <div className={classes.header}>
        <Link to="/" className={classes.logo}>
          <Hidden smDown>
            <DMSALogo size="md" />
          </Hidden>
          <Hidden smUp>
            <DMSALogo size="responsive" />
          </Hidden>
        </Link>
      </div>

      {title && (
        <Typography className={classes.MainName} variant="h2" component="h1" align="center">
          {title}
        </Typography>
      )}
      <Fade in timeout={ANIMATIONS.duration}>
        <div className={classes.content}>{children}</div>
      </Fade>
      <div className={classes.footer}>
        <PartnerStrip logos={PARTNER_LOGOS} />
      </div>
    </PageWrapper>
  );
}

export default MainLayout;

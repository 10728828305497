const resolver = (variables: any) => {
  const response = {
    data: {
      acceptStaffLegalNotices: {
        id: 'tbullen',
        email: 'tbullen@omnigenbiodata.co.uk',
        firstName: 'Thomas',
        surname: 'Bullen',
        mobile: '',
        privacyAccepted: variables?.privacyAccepted,
        termsAccepted: variables?.termsAccepted,
      },
    },
  };
  return response;
};
export default resolver;

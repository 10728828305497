import { RootState } from '../index';
import { StoreStatus, KeyValues } from '../../core/types/common.types';

export const isBusySelector = (state: RootState): boolean =>
  state.totals.status && state.totals.status === StoreStatus.BUSY;

export const hasErrorSelector = (state: RootState): boolean =>
  state.totals.errors && state.totals.errors.length > 0 ? true : false;

export const hasSuccessSelector = (state: RootState): boolean => (state.totals.result ? true : false);

export const totalsSelector = (state: RootState): any => state.totals.result;

export const formValueSelector = (state: RootState): KeyValues => state.totals.values || {};

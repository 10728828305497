import React, { useState, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import { Loader } from '@omnigenbiodata/ui';
import PieChartIcon from '@mui/icons-material/PieChart';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import IconButton from '@mui/material/IconButton';
import { useStyles } from './component.styles';

export enum DataView {
  DONUT = 'donut',
  PIE = 'pie',
  CHART = 'chart',
  TABLE = 'table',
}

export interface ChartContainerProps {
  children: (dataView: DataView) => ReactNode;
  title: string;
  subtitle?: string;
  isBusy?: boolean;
  loadingText?: string;
  dataViews?: DataView[];
}

const dataViewIcons: any = {
  donut: <DonutLargeIcon />,
  pie: <PieChartIcon />,
  chart: <ShowChartOutlinedIcon />,
  table: <TocOutlinedIcon />,
};

function ChartContainer({
  children,
  dataViews = [DataView.CHART, DataView.TABLE],
  isBusy = false,
  loadingText = 'Loading',
  subtitle,
  title,
}: ChartContainerProps) {
  const [dataView, setDataView] = useState<DataView>(dataViews[0]);
  const { classes } = useStyles();
  return (
    <Paper elevation={1} className={classes.chartWrapper}>
      <Box p={2}>
        <Toolbar className={classes.chartToolbar} variant="dense">
          <span className={classes.chartTitle}>
            <Typography variant="h5" component="h3" align="left" color="textSecondary">
              {title}
            </Typography>
            <Typography variant="body2" component="h4" align="left" color="primary">
              {subtitle}
            </Typography>
          </span>
          {dataViews &&
            dataViews.length > 1 &&
            dataViews.map((viewKey, index) => (
              <IconButton
                key={`${title}${index}`}
                edge="end"
                data-testid={`${viewKey}-btn`}
                onClick={() => {
                  setDataView(viewKey);
                }}
                color={dataView === viewKey ? 'primary' : 'default'}
              >
                {dataViewIcons[viewKey]}
              </IconButton>
            ))}
        </Toolbar>
        <Loader label={loadingText} isVisible={isBusy} isInline />
        {!isBusy && children(dataView)}
      </Box>
    </Paper>
  );
}

export default ChartContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormRadioBlockGroup, Loader } from '@omnigenbiodata/ui';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../../store';
import { HOSPTIAL_NUM_PREFIX } from '../../../../../../../../../../core/constants/forms.constant';

import InnerLayout from '../../../../../../../../../../layout/Inner';
import Heading1 from '../../../../../../../../../../components/text/Heading1';

import ROUTES, { URL_PARAMS } from '../../../../../../../../../../core/constants/routes.constant';
import ContentCard from '../../../../../../../../../../components/content/ContentCard';
import {
  withdrawalForward,
  isBusySelector,
  participantProfileSelector,
} from '../../../../../../../../../../store/participant';

function WithdrawalOptionScene() {
  const { t } = useTranslation('portal');
  const { hospitalNo } = useParams<any>();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(participantProfileSelector);
  const isBusy = useAppSelector(isBusySelector);

  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    withdrawalOption: yup.string().required(t('withdrawalOption.subtitle')),
  });

  const formik = useFormik({
    initialValues: {
      withdrawalOption: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(withdrawalForward(values));
      navigate(ROUTES.participantWithdrawalReason.replace(URL_PARAMS.hospitalNo, hospitalNo!));
    },
  });

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        {!isBusy && profile && (
          <>
            <Heading1 icon={GroupIcon}>
              {t('participant.title')} &gt;{' '}
              <span style={{ fontSize: '0.7em' }}>
                {HOSPTIAL_NUM_PREFIX}
                {hospitalNo?.toString()}
              </span>
            </Heading1>

            <ContentCard>
              <form onSubmit={formik.handleSubmit}>
                <Box mb={8}>
                  <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {t('withdrawalOption.title')}
                  </Typography>
                  <Typography variant="h5" component="h2" align="center" gutterBottom>
                    <strong>{t('withdrawalOption.subtitle')}</strong>
                  </Typography>

                  <FormRadioBlockGroup
                    name="withdrawalOption"
                    labelCentered
                    label={t('withdrawalOption.para1')}
                    onChange={formik.handleChange}
                    value={formik.values.withdrawalOption}
                    options={[
                      {
                        label: t('SharingOnly.label', { ns: 'withdrawals' }),
                        value: 'SharingOnly',
                        content: t('SharingOnly.content', {
                          ns: 'withdrawals',
                        }),
                      },
                      {
                        label: t('CollectionOnly.label', { ns: 'withdrawals' }),
                        value: 'CollectionOnly',
                        content: t('CollectionOnly.content', {
                          ns: 'withdrawals',
                        }),
                      },
                      {
                        label: t('SharingAndCollection.label', {
                          ns: 'withdrawals',
                        }),
                        value: 'SharingAndCollection',
                        content: t('SharingAndCollection.content', {
                          ns: 'withdrawals',
                        }),
                      },
                      {
                        label: t('Complete.label', { ns: 'withdrawals' }),
                        value: 'Complete',
                        content: t('Complete.content', { ns: 'withdrawals' }),
                      },
                    ]}
                    showOr={false}
                    hideLabel={false}
                    error={formik.errors.withdrawalOption}
                    touched={formik.touched.withdrawalOption}
                  />
                </Box>
                <ButtonRow
                  backLabel={t('withdrawalOption.back')}
                  forwardLabel={t('withdrawalOption.button')}
                  forwardColor="error"
                  buttonSize="large"
                />
              </form>
            </ContentCard>
          </>
        )}
      </InnerLayout>
      <Loader label="Busy" isVisible={isBusy} />
    </>
  );
}

export default WithdrawalOptionScene;

import { createAsyncThunk } from '@reduxjs/toolkit';
import participantApi from '../../core/api/participants/participantApi';
import {
  ParticipantLookupResponse,
  ParticipantLookupRejection,
  ParticipantProfileInput,
  WithdrawParticipantResponse,
  WithdrawParticipantInput,
} from '../../core/types/participants.types';
import { GraphQlError } from '../../core/types/common.types';
import { KeyValues } from '../../core/types/common.types';
import { stepForward } from '.';

// Thunks
export const getParticipantByHospitalNoThunk = createAsyncThunk<
  ParticipantLookupResponse,
  KeyValues,
  ParticipantLookupRejection
>('participant/getParticipantByHospitalNo', async (values, { rejectWithValue, dispatch }) => {
  try {
    dispatch(stepForward(values));
    const response = await participantApi.getParticipantByHospitalNo(values);
    if (response.data?.getParticipantByHospitalNo) {
      return response.data.getParticipantByHospitalNo;
    }
  } catch (response: any) {
    return rejectWithValue(response.errors);
  }
});

export const updateParticipantProfileThunk = createAsyncThunk<
  ParticipantLookupResponse,
  ParticipantProfileInput,
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/updateParticipantProfile', async (values, { rejectWithValue }) => {
  try {
    const response = await participantApi.updateParticipantProfile(values);
    if (response.data?.updateParticipantProfile) {
      return response.data.updateParticipantProfile;
    }
  } catch (response: any) {
    return rejectWithValue(response.errors);
  }
});

export const adminWithdrawParticipantThunk = createAsyncThunk<
  WithdrawParticipantResponse,
  WithdrawParticipantInput,
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/adminWithdrawParticipant', async (values, { rejectWithValue }) => {
  try {
    const response = await participantApi.adminWithdrawParticipant(values);
    if (response.data?.adminWithdrawParticipant) {
      return response.data.adminWithdrawParticipant;
    }
    return rejectWithValue(response.errors);
  } catch (response: any) {
    return rejectWithValue(response.errors);
  }
});

import React, { ElementType, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export interface Heading1Props {
  children: ReactNode;
  menu?: ReactNode;
  icon?: ElementType | null;
}
const Heading1 = ({ icon = null, children, menu = null }: Heading1Props) => {
  const IconComponent: ElementType | null = icon;

  return (
    <Box mb={4}>
      <Toolbar variant="dense" style={{ padding: 0 }}>
        {icon && IconComponent && (
          <>
            <IconComponent fontSize="large" color="action" style={{ verticalAlign: 'middle', marginRight: 10 }} />{' '}
          </>
        )}
        <Typography variant="h4" component="h1" color="textSecondary" style={{ flexGrow: 1 }}>
          {children}
        </Typography>
        {menu}
      </Toolbar>
    </Box>
  );
};

export default Heading1;

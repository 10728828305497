import { createSlice } from '@reduxjs/toolkit';
import { MyStaffProfile } from '../../core/types/user.types';
import { KeyValues, StoreStatus, GraphQlError } from '../../core/types/common.types';
import { getMyStaffProfileThunk, acceptStaffLegalNoticesThunk } from './thunks';

// Initial State
export interface UserState {
  errors: GraphQlError[] | null;
  result: MyStaffProfile | null;
  setupRequired: boolean;
  status: StoreStatus;
  values: KeyValues | null;
}

export const initialState: UserState = {
  errors: null,
  result: null,
  setupRequired: false,
  status: StoreStatus.IDLE,
  values: null,
};

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserStore: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getMyStaffProfileThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(getMyStaffProfileThunk.fulfilled.type, (state, action: any) => {
      state.errors = null;
      state.result = action.payload;
      state.setupRequired = action.payload ? false : true;
      state.status = StoreStatus.IDLE;
    });
    builder.addCase(getMyStaffProfileThunk.rejected.type, (state, action: any) => {
      state.errors = action.payload;
      state.result = null;
      state.status = StoreStatus.ERROR;
    });
    builder.addCase(acceptStaffLegalNoticesThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(acceptStaffLegalNoticesThunk.fulfilled.type, (state, action: any) => {
      state.errors = null;
      state.result = action.payload;
      state.setupRequired = false;
      state.status = StoreStatus.IDLE;
    });
    builder.addCase(acceptStaffLegalNoticesThunk.rejected.type, (state, action: any) => {
      state.errors = action.payload;
      state.status = StoreStatus.ERROR;
    });
  },
});

// Action Creators
export * from './thunks';
export * from './selectors';
export const { resetUserStore } = userSlice.actions;
export default userSlice.reducer;

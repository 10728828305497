import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import { ButtonRow, FormInput, Loader } from '@omnigenbiodata/ui';
import { useFormik } from 'formik';
import * as yup from 'yup';

import ROUTES, { URL_PARAMS } from '../../../../../../core/constants/routes.constant';
import InnerLayout from '../../../../../../layout/Inner';
import Heading1 from '../../../../../../components/text/Heading1';
import Heading2 from '../../../../../../components/text/Heading2';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  getParticipantByHospitalNoThunk,
  resetParticipantResult,
  isBusySelector,
  hasLookupErrorSelector,
  participantProfileSelector,
  lookupErrorTypeSelector,
  formValueSelector,
} from '../../../../../../store/participant';
import { LookupErrorTypes } from '../../../../../../core/types/common.types';

function ParticipantsLookupScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const profile = useAppSelector(participantProfileSelector);
  const hasLookupError = useAppSelector(hasLookupErrorSelector);
  const lookupErrorType = useAppSelector(lookupErrorTypeSelector);
  const lookupValues = useAppSelector(formValueSelector);

  useEffect(() => {
    dispatch(resetParticipantResult());
  }, [dispatch]);

  const validationSchema = yup.object({
    hospitalNo: yup.lazy((value: string) =>
      value && value.charAt(0).toUpperCase() === 'D'
        ? yup
            .string()
            .required(
              t('hospitalNumRequired', {
                ns: 'validation',
              }),
            )
            .matches(/^DMSA[0-9]{8}-\w{2}$/, t('participantIdFormatError', { ns: 'validation' }))
        : yup
            .string()
            .required(
              t('hospitalNumRequired', {
                ns: 'validation',
              }),
            )
            .matches(
              /^[0-9]+$/,
              t('hospitalNoFormatError', {
                ns: 'validation',
              }),
            )
            .min(
              8,
              t('hospitalNoLengthError', {
                ns: 'validation',
              }),
            )
            .max(
              8,
              t('hospitalNoLengthError', {
                ns: 'validation',
              }),
            ),
    ),
  });

  const formik = useFormik({
    initialValues: {
      hospitalNo: '',
    },
    validationSchema,
    onSubmit: ({ hospitalNo }) => {
      dispatch(getParticipantByHospitalNoThunk({ hospitalNo }));
    },
  });

  if (profile && formik.values.hospitalNo) {
    return <Navigate to={ROUTES.participant.replace(URL_PARAMS.hospitalNo, profile.hospitalNo)} />;
  }

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        <form onSubmit={formik.handleSubmit}>
          <Heading1 icon={GroupIcon}>{t('participantLookup.title')}</Heading1>

          <Paper elevation={9}>
            <Box p={6}>
              <Heading2 align="center">{t('participantLookup.subtitle')}</Heading2>
              <Typography paragraph variant="body1" align="center">
                {t('participantLookup.para1')}
              </Typography>
              <Box mb={4}>
                <Grid container spacing={2}>
                  <Grid item xs={1} md={3} />
                  <Grid item xs={10} md={6}>
                    {hasLookupError && lookupErrorType.includes(LookupErrorTypes.NOT_FOUND) && (
                      <Alert color="error">
                        {t('participantLookup.errors.RecordNotFoundError', {
                          hospitalNo: lookupValues?.hospitalNo,
                        })}
                      </Alert>
                    )}
                    {hasLookupError && !lookupErrorType.includes(LookupErrorTypes.NOT_FOUND) && (
                      <Alert color="error">{t('participantLookup.errors.UnexpectedError')}</Alert>
                    )}
                    <FormInput
                      error={formik.errors.hospitalNo}
                      name="hospitalNo"
                      label={t('participantLookup.label')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.hospitalNo}
                      value={formik.values.hospitalNo}
                    />
                  </Grid>
                </Grid>
              </Box>
              <ButtonRow
                showBack={false}
                forwardColor="primary"
                justifyContent="center"
                forwardLabel={t('participantLookup.button')}
                buttonSize="large"
                forwardIcon={<SearchIcon style={{ fontSize: 30 }} />}
              />
            </Box>
          </Paper>
        </form>
      </InnerLayout>
      <Loader label={t('participantLookup.busy')} isVisible={isBusy} />
    </>
  );
}

export default ParticipantsLookupScene;

import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { PartnerStrip, useAuth, Hidden } from '@omnigenbiodata/ui';

import ANIMATIONS from '../../core/constants/animations.constant';
import { PARTNER_LOGOS } from '../../core/constants/content.constant';
import SideMenu from '../../components/navigation/SideMenu';

import ROUTES from '../../core/constants/routes.constant';
import PortalPage from '../../components/layout/PortalPage';
import { useAppSelector } from '../../store';
import { userProfileSelector } from '../../store/user';
import { useStyles } from './component.styles';

interface InnerLayoutProps {
  children: ReactNode;
  title?: string;
  title2?: string;
}

function InnerLayout({ children, title = '', title2 = '' }: InnerLayoutProps) {
  const { classes } = useStyles();
  const [isMenuOpenFlag, setIsMenuOpenFlag] = useState(false);
  const { signOut } = useAuth();
  const profile = useAppSelector(userProfileSelector);

  const handleToggleMenu = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsMenuOpenFlag(open);
  };

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Toolbar>
          <IconButton
            onClick={handleToggleMenu(true)}
            edge="start"
            className={classes.appBarHamburger}
            data-testid="menu-btn"
          >
            <MenuIcon />
          </IconButton>
          <Link to={ROUTES.root}>
            <img src="/images/logo-small.png" alt="Discover Me Logo" className={classes.imgLogo} />
          </Link>
          <span className={classes.appBarDivider}></span>
          <Typography variant="h6" className={classes.appBarTitle}>
            <strong>{title}</strong>
            {title2}
          </Typography>

          <Hidden smDown>
            <Box display={'flex'} alignItems={'center'}>
              {profile && (
                <>
                  <Typography variant="body1" className={classes.appBarUser}>
                    {profile.firstName} {profile.surname}
                  </Typography>{' '}
                  <IconButton edge="end" className={classes.appBarUserIconBtn} data-testid="account-btn">
                    <PersonIcon />
                  </IconButton>
                </>
              )}

              <Button
                color="inherit"
                onClick={() => {
                  signOut();
                }}
                data-testid="signout-btn"
              >
                Sign Out
              </Button>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <SideMenu isOpen={isMenuOpenFlag} onToggle={handleToggleMenu} />
      <div className={classes.layoutWrapper}>
        <div className={classes.layoutContent}>
          <Container>
            <Fade in timeout={ANIMATIONS.duration}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <PortalPage>{children}</PortalPage>
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </div>
        <div className={classes.layoutFooter}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs>
                <PartnerStrip logos={PARTNER_LOGOS} />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default InnerLayout;

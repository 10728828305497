import { createAsyncThunk } from '@reduxjs/toolkit';
import monitoringApi from '../../core/api/monitoring/monitoringApi';
import { DateSelectionInputs } from '../../core/types/monitoring.types';
import { TotalsResponse, TotalsRejectionResponse } from '../../core/types/totals.types';

export const fetchTotalsThunk = createAsyncThunk<TotalsResponse, DateSelectionInputs, TotalsRejectionResponse>(
  'totals/fetchTotals',
  async (params, { rejectWithValue }) => {
    try {
      const result = await monitoringApi.fetchTotals(params);

      return result;
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  },
);

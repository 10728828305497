import React, { ReactNode, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material';

export interface ContentCardProps {
  children: ReactNode;
}

function TabWrapper({ children }: ContentCardProps) {
  const [value, setValue] = useState('1');

  const theme = useTheme();

  const handleTabChange = (event: React.ChangeEvent<object>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.success.main },
        }}
        onChange={handleTabChange}
        aria-label="Participant Info"
      >
        <Tab sx={{ width: (theme) => theme.spacing(20) }} label="Profile" value="1" />
        <Tab sx={{ width: (theme) => theme.spacing(20) }} label="Enrolment" value="2" />
        <Tab sx={{ width: (theme) => theme.spacing(20) }} label="Samples" value="3" />
      </TabList>
      {children}
    </TabContext>
  );
}

export default TabWrapper;

import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import consentReducer from './consent';
import monitoringReducer from './monitoring';
import participantReducer from './participant';
import summaryReducer from './summary';
import userReducer from './user';
import totalsReducer from './totals';

const store = configureStore({
  reducer: {
    consent: consentReducer,
    monitoring: monitoringReducer,
    participant: participantReducer,
    summary: summaryReducer,
    user: userReducer,
    totals: totalsReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => any = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

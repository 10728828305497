import React, { ReactNode, ElementType } from 'react';
import Typography from '@mui/material/Typography';

export interface Heading2Props {
  children: ReactNode;
  icon?: ElementType | null;
  align?: string;
  color?: 'textSecondary' | 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'error';
}
const Heading2 = ({ align = 'left', color = 'textSecondary', children, icon = null }: Heading2Props) => {
  const IconComponent: ElementType | null = icon;

  return (
    <Typography variant="h5" component="h2" align={align as any} color={color} paragraph>
      {icon && IconComponent && (
        <>
          <span style={{ fontSize: 30 }}>
            <IconComponent fontSize="inherit" color="inherit" style={{ display: 'inline', verticalAlign: 'middle' }} />{' '}
          </span>
        </>
      )}
      {children}
    </Typography>
  );
};

export default Heading2;

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: 290,
    zIndex: 90,
  },
  icon: {
    marginRight: 10,
    fontSize: '1.4em',
    color: theme.palette.primary.main,
  },
}));

const resolver = (variables: any) => {
  let response;
  response = {
    data: {
      getMyProfile: {
        id: 'tbullen',
        email: 'tbullen@omnigenbiodata.co.uk',
        firstName: 'Thomas',
        surname: 'Bullen',
        mobile: '',
        privacyAccepted: '',
        termsAccepted: '',
      },
    },
  };
  return response;
};
export default resolver;

export const departmentData = [
  'Addingtons Oncology',
  'Anaesthetics',
  'Critical Care',
  'Gastroenterology',
  'Gynaecology',
  'Haematology',
  'Neurology',
  'Ophthalmology',
  'Orthopaedics',
  'Rheumatology',
  'Urology',
  'Obstetrics and gynaecology',
]
  .map((item) => {
    return {
      bucket: item,
      i: Math.ceil(Math.random() * 8),
      m: Math.ceil(Math.random() * 50),
      w: Math.ceil(Math.random() * 50),
    };
  })
  .sort((a, b) => {
    const aTotal = a.m + a.w + a.i;
    const bTotal = b.m + b.w + b.i;
    if (aTotal > bTotal) {
      return -1;
    } else if (aTotal < bTotal) {
      return 1;
    }
    return 0;
  });

export const ageGroupData = [
  '18-25',
  '25-30',
  '30-35',
  '35-40',
  '40-45',
  '45-50',
  '50-55',
  '55-60',
  '60-65',
  '65-70',
  '70-75',
  '75+',
].map((item) => {
  return {
    bucket: item,
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 50),
    w: Math.ceil(Math.random() * 50),
  };
});

export const enrolmentData = [
  {
    bucket: 'Jan 1',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 2',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 3',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 4',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 5',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 6',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 7',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 8',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 9',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 10',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 11',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 12',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 13',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 14',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 15',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 16',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
  {
    bucket: 'Jan 17',
    i: Math.ceil(Math.random() * 8),
    m: Math.ceil(Math.random() * 120),
    w: Math.ceil(Math.random() * 120),
  },
];

export const genderData = [
  { name: 'Male', value: 400 },
  { name: 'Female', value: 300 },
  { name: 'Intersex', value: 22 },
];

export const populationData = [
  { name: 'African', value: 400 },
  { name: 'Coloured', value: 300 },
  { name: 'Indian/Asian', value: 22 },
  { name: 'White', value: 18 },
  { name: 'Other', value: 12 },
];

export const signatureData = [
  { name: 'Signed', value: 870 },
  { name: 'Fingerprint', value: 9 },
];

export const ehrData = [
  { name: 'Yes', value: 600 },
  { name: 'No', value: 200 },
];

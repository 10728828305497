import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { SummaryTable } from '@omnigenbiodata/ui';
import { BsDroplet } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import TabPanel from '@mui/lab/TabPanel';
import Heading2 from '../../../../../../../../../../components/text/Heading2';
import { ParticipantProfile } from '../../../../../../../../../../core/types/participants.types';

export interface SamplesTabProps {
  profile: ParticipantProfile;
}

function SamplesTab({ profile }: SamplesTabProps) {
  const { t } = useTranslation('portal');
  return (
    <TabPanel value="3" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Paper>
        <Box p={4}>
          <Heading2 color="textPrimary" icon={BsDroplet}>
            {t('participantSamples.title')}
          </Heading2>
          {!profile?.serumSampleID && (
            <Alert variant="outlined" color="error">
              {t('participantSamples.errors.RecordNotFoundError')}
            </Alert>
          )}
          {profile?.serumSampleID && (
            <>
              <Box mb={2}>
                <SummaryTable
                  label="Serum Sample"
                  rows={[
                    {
                      key: 'serumSampleID',
                      label: 'Serum Barcode',
                      value: profile?.serumSampleID,
                    },
                    {
                      key: 'serumStatus',
                      label: 'Serum Status',
                      value: profile?.serumStatus,
                    },
                  ]}
                />
              </Box>
              <Box mb={2}>
                <SummaryTable
                  label="Whole Blood Sample"
                  rows={[
                    {
                      key: 'wholeBloodSampleID',
                      label: 'Whole Blood Barcode',
                      value: profile?.wholeBloodSampleID,
                    },
                    {
                      key: 'wholeBloodStatus',
                      label: 'Whole Blood Status',
                      value: profile?.wholeBloodStatus,
                    },
                  ]}
                />
              </Box>
              <Box mb={2}>
                <SummaryTable
                  label="Plasma Sample"
                  rows={[
                    {
                      key: 'plasmaSampleID',
                      label: 'Plasma Barcode',
                      value: profile?.plasmaSampleID,
                    },
                    {
                      key: 'plasmaStatus',
                      label: 'Plasma Status',
                      value: profile?.plasmaStatus,
                    },
                  ]}
                />
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </TabPanel>
  );
}

export default SamplesTab;

export const response = {
  data: {
    reportEnrolmentsBySignatureMethod: [
      {
        numEnrolments: 496,
        signatureMethod: 'signature',
      },
      {
        numEnrolments: 20,
        signatureMethod: 'fingerprint',
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

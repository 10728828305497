import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import {
  updateParticipantProfileThunk,
  participantProfileSelector,
  isBusySelector,
} from '../../../../../../../../store/participant';
import {
  getParticipantConsentFormLinkThunk,
  resultSelector,
  isBusySelector as isConsentBusySelector,
  hasErrorSelector as hasConsentLinkErrorSelector,
} from '../../../../../../../../store/consent';

import { HOSPTIAL_NUM_PREFIX } from '../../../../../../../../core/constants/forms.constant';
import Tabs from './components/TabWrapper';
import ROUTES from '../../../../../../../../core/constants/routes.constant';
import Heading1 from '../../../../../../../../components/text/Heading1';
import ProfileSummary from '../../components/ProfileSummary';
import ProfileTab from './components/ProfileTab';
import SamplesTab from './components/SamplesTab';
import EnrolmentTab from './components/EnrolmentTab';
import InnerLayout from '../../../../../../../../layout/Inner';
import { Loader } from '@omnigenbiodata/ui';

function ParticipantsResultScene() {
  const { t } = useTranslation('portal');
  const [hasInitiatedDownload, setHasInitiatedDownload] = useState(false);
  const dispatch = useAppDispatch();
  const { hospitalNo } = useParams<any>();
  const profile = useAppSelector(participantProfileSelector);
  const signedPDFUrl = useAppSelector(resultSelector);
  const signedPDFIsBusy = useAppSelector(isConsentBusySelector);
  const hasConsentLinkError = useAppSelector(hasConsentLinkErrorSelector);
  const isProfileBusy = useAppSelector(isBusySelector);

  useEffect(() => {
    if (signedPDFUrl && hasInitiatedDownload) {
      window.open(signedPDFUrl, '_blank');
    }
  }, [signedPDFUrl, hasInitiatedDownload]);

  const handleSaveProfile = (values: any) => {
    dispatch(updateParticipantProfileThunk(values));
  };

  const handleConsentLinkRequest = () => {
    if (profile) {
      setHasInitiatedDownload(true);
      dispatch(getParticipantConsentFormLinkThunk({ participantId: profile?.id }));
    }
  };

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        {profile && (
          <>
            <Heading1 icon={GroupIcon}>
              <Link to={ROUTES.participantLookup} style={{ color: 'inherit', textDecoration: 'none' }}>
                {t('participant.title')}
              </Link>{' '}
              &gt;{' '}
              <span style={{ fontSize: '0.7em' }}>
                {HOSPTIAL_NUM_PREFIX}
                {hospitalNo?.toString()}
              </span>
            </Heading1>
            <Box mb={4}>
              <ProfileSummary
                firstName={profile.firstName}
                lastName={profile.surname}
                deceased={profile.deceased}
                dataHasArrived={profile.dataHasArrived}
                sub={`${HOSPTIAL_NUM_PREFIX}${hospitalNo}`}
                status={t(`${profile.withdrawalOption || 'Default'}.status`, {
                  ns: 'withdrawals',
                })}
                statusColor={!profile.withdrawalOption ? 'primary' : 'secondary'}
              />
            </Box>
            <Tabs>
              <ProfileTab
                profile={profile}
                onSave={handleSaveProfile}
                onSaveEmail={() => undefined}
                onSaveMobile={() => undefined}
              />
              <EnrolmentTab
                profile={profile}
                onConsentLink={handleConsentLinkRequest}
                isConsentLinkBusy={signedPDFIsBusy}
                hasConsentLinkError={hasConsentLinkError && hasInitiatedDownload}
              />
              <SamplesTab profile={profile} />
            </Tabs>
          </>
        )}
      </InnerLayout>
      <Loader isVisible={isProfileBusy} label="Updating Profile" />
    </>
  );
}

export default ParticipantsResultScene;

export const URL_PARAMS = {
  hospitalNo: ':hospitalNo',
};

export const ROOT = `/`;
export const AUTH_ROOT = `${ROOT}auth/`;
export const CONSENT_ROOT = `${ROOT}consent/`;
export const SPLASH_ROOT = `${ROOT}splash/`;
export const PORTAL_ROOT = `${ROOT}portal/`;
export const MONITORING_ROOT = `${PORTAL_ROOT}monitoring/`;
export const PARTICIPANTS_ROOT = `${PORTAL_ROOT}participants/`;
export const PARTICIPANT_ROOT = `${PARTICIPANTS_ROOT}${URL_PARAMS.hospitalNo}/`;
export const WITHDRAWAL_ROOT = `${PARTICIPANT_ROOT}withdraw/`;

export const ROUTES = {
  root: ROOT,
  auth: AUTH_ROOT,
  authMultiFactor: `${AUTH_ROOT}mfa`,
  authNewPassword: `${AUTH_ROOT}newpassword`,
  authResetPassword: `${AUTH_ROOT}resetpassword`,
  authSubmitResetPassword: `${AUTH_ROOT}submitresetpassword`,
  authSignOut: `${AUTH_ROOT}signout`,
  portal: PORTAL_ROOT,
  participantLookup: PARTICIPANTS_ROOT,
  participant: PARTICIPANT_ROOT,
  participantWithdrawal: WITHDRAWAL_ROOT,
  participantWithdrawalReason: `${WITHDRAWAL_ROOT}reason`,
  participantWithdrawalConfirm: `${WITHDRAWAL_ROOT}confirm`,
  monitoring: MONITORING_ROOT,
  splash: SPLASH_ROOT,
  consentTerms: CONSENT_ROOT,
  consentPrivacy: `${CONSENT_ROOT}privacy`,
};

export default ROUTES;

import { RootState } from '../index';
import { GraphQlError, StoreStatus } from '../../core/types/common.types';
import { MyStaffProfile } from '../../core/types/user.types';

export const hasLookupErrorSelector = (state: RootState): boolean =>
  state.user.errors && state.user.errors.length > 0 ? true : false;

export const lookupErrorMessagesSelector = (state: RootState): string[] =>
  state.user.errors ? state.user.errors.map((error: GraphQlError) => error.message) : [];

export const hasLookupSuccessSelector = (state: RootState): boolean => (state.user.result?.id ? true : false);

export const userIdSelector = (state: RootState): string | null =>
  state.user.result?.id ? state.user.result?.id : null;

export const userProfileSelector = (state: RootState): MyStaffProfile | null =>
  state.user.result ? state.user.result : null;

export const isBusySelector = (state: RootState): boolean => state.user.status === StoreStatus.BUSY;

export const setupSelector = (state: RootState): boolean => state.user.setupRequired;

export const response = {
  data: {
    reportEnrolmentsByDept: [
      {
        departmentOfEnrolment: 'Bone Marrow Transplant',
        numEnrolments: 7,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Bone Marrow Transplant',
        numEnrolments: 3,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Bone Marrow Transplant',
        numEnrolments: 7,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Burns',
        numEnrolments: 5,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Burns',
        numEnrolments: 3,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Burns',
        numEnrolments: 6,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Dermatology',
        numEnrolments: 9,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Dermatology',
        numEnrolments: 1,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Dermatology',
        numEnrolments: 10,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Medicine',
        numEnrolments: 8,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Medicine',
        numEnrolments: 2,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Medicine',
        numEnrolments: 9,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Oncology Urology Clinic',
        numEnrolments: 10,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Oncology Urology Clinic',
        numEnrolments: 5,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Oncology Urology Clinic',
        numEnrolments: 8,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Orthopaedic',
        numEnrolments: 11,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Orthopaedic',
        numEnrolments: 9,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Plastics Breast Reconstruction',
        numEnrolments: 8,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Plastics Breast Reconstruction',
        numEnrolments: 12,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Renal Surgery',
        numEnrolments: 4,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Renal Surgery',
        numEnrolments: 4,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Renal Surgery',
        numEnrolments: 9,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Respiratory',
        numEnrolments: 12,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Respiratory',
        numEnrolments: 5,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Respiratory',
        numEnrolments: 5,
        sex: 'Male',
      },
      {
        departmentOfEnrolment: 'Speech Therapy',
        numEnrolments: 4,
        sex: 'Female',
      },
      {
        departmentOfEnrolment: 'Speech Therapy',
        numEnrolments: 1,
        sex: 'Intersex',
      },
      {
        departmentOfEnrolment: 'Speech Therapy',
        numEnrolments: 9,
        sex: 'Male',
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

import {
  getParticipantByHospitalNoQuery,
  studyChartQuery,
  studySummaryQuery,
  getMyStaffProfileQuery,
  reportDailyEnrolmentsWithdrawalsQuery,
  reportAgeGroupDistributionQuery,
  reportEnrolmentsByDeptQuery,
  reportEnrolmentsBySexQuery,
  reportEnrolmentsByPopulationGroupQuery,
  reportEnrolmentsBySignatureMethodQuery,
  reportEnrolmentsByEHROptInQuery,
} from '../../graphql/queries';
import defaultResponse from './responses/default';
import getParticipantByHospitalNoQueryMock from './responses/getParticipantByHospitalNoQuery';
import studyChartQueryMock from './responses/studyChartQuery';
import studySummaryQueryMock from './responses/studySummaryQuery';
import getMyStaffProfileQueryMock from './responses/getMyStaffProfileQuery';
import acceptStaffLegalNoticesMock from './responses/acceptStaffLegalNotices';
import reportDailyEnrolmentsWithdrawalsQueryMock from './responses/reportDailyEnrolmentsWithdrawalsQuery';
import reportAgeGroupDistributionQueryMock from './responses/reportAgeGroupDistributionQuery';
import reportEnrolmentsByDeptQueryMock from './responses/reportEnrolmentsByDeptQuery';
import reportEnrolmentsBySexQueryMock from './responses/reportEnrolmentsBySexQuery';
import reportEnrolmentsBySignatureMethodQueryMock from './responses/reportEnrolmentsBySignatureMethodQuery';
import reportEnrolmentsByPopulationGroupQueryMock from './responses/reportEnrolmentsByPopulationGroupQuery';
import reportEnrolmentsByEHROptInQueryMock from './responses/reportEnrolmentsByEHROptInQuery';
import { acceptStaffLegalNoticesMutation } from '../../graphql/mutations';

const randomTime = () => Math.ceil(Math.random() * 8000) + 2000;

const resolveResponseFromQuery = (query: any, variables: any) => {
  switch (query) {
    case getParticipantByHospitalNoQuery:
      return getParticipantByHospitalNoQueryMock(variables);
    case studyChartQuery:
      return studyChartQueryMock(variables);
    case studySummaryQuery:
      return studySummaryQueryMock(variables);
    case getMyStaffProfileQuery:
      return getMyStaffProfileQueryMock(variables);
    case acceptStaffLegalNoticesMutation:
      return acceptStaffLegalNoticesMock(variables);
    case reportDailyEnrolmentsWithdrawalsQuery:
      return reportDailyEnrolmentsWithdrawalsQueryMock(variables);
    case reportAgeGroupDistributionQuery:
      return reportAgeGroupDistributionQueryMock(variables);
    case reportEnrolmentsByDeptQuery:
      return reportEnrolmentsByDeptQueryMock(variables);
    case reportEnrolmentsBySexQuery:
      return reportEnrolmentsBySexQueryMock(variables);
    case reportEnrolmentsByPopulationGroupQuery:
      return reportEnrolmentsByPopulationGroupQueryMock(variables);
    case reportEnrolmentsBySignatureMethodQuery:
      return reportEnrolmentsBySignatureMethodQueryMock(variables);
    case reportEnrolmentsByEHROptInQuery:
      return reportEnrolmentsByEHROptInQueryMock(variables);
    default:
      return defaultResponse;
  }
};

export const API = {
  graphql: (options: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(resolveResponseFromQuery(options.query, options.variables?.input || options.variables));
      }, randomTime());
    });
  },
};

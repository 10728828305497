export const PARTNER_LOGOS = [
  {
    img: '/images/kwazulu-natal-province.png',
    alt: 'Kwazulu-Natal Province',
  },
  {
    img: '/images/kwazulu-uni-logo.png',
    alt: 'University of Kwazulu-Natal',
  },
  { img: '/images/omnigen-logo-2-x.png', alt: 'Omnigen' },
];

export const CURRENT_TERMS_VERSION = 'TERMSDISABLED';
export const CURRENT_PRIVACY_VERSION = 'TERMSDISABLED';

export const SUPPORT_EMAIL = 'researchdmsa@omnigenbiodata.co.uk';
export const STUDY_EMAIL = 'discovermesa@ukzn.ac.za';
export const STUDY_TEL = '083 4633 156';
export const STUDY_URL = 'www.omnigenbiodata.co.uk/dmsa';
export const STUDY_ADDRESS =
  'For Attn: Professor Ayesha Motala, Department of Diabetes and Endocrinology, University of KwaZulu-Natal, 719 Umbilo Road, Room 343, Congella, Durban, 401';
export const MICROSITE_URL = 'https://www.omnigenbiodata.co.uk/dmsa';
export const MICROSITE_URL_FRIENDLY = 'www.omnigenbiodata.co.uk/dmsa';
export const TERMS = 'https://www.omnigenbiodata.co.uk/dmsa';
export const TERMS_EMAIL = 'TODO';
export const DPO_EMAIL = 'dpo@omnigenbiodata.co.uk';

const CONTENT = {
  PARTNER_LOGOS,
  SUPPORT_EMAIL,
  STUDY_EMAIL,
  STUDY_TEL,
  STUDY_URL,
  STUDY_ADDRESS,
  MICROSITE_URL,
  MICROSITE_URL_FRIENDLY,
  TERMS_EMAIL,
  DPO_EMAIL,
  CURRENT_TERMS_VERSION,
  CURRENT_PRIVACY_VERSION,
};
export default CONTENT;

export const response = {
  data: {
    reportEnrolmentsByPopulationGroup: [
      {
        numEnrolments: 46,
        populationGroup: 'African',
      },
      {
        numEnrolments: 45,
        populationGroup: 'Coloured',
      },
      {
        numEnrolments: 53,
        populationGroup: 'Indian or Asian',
      },
      {
        numEnrolments: 49,
        populationGroup: 'Other',
      },
      {
        numEnrolments: 32,
        populationGroup: 'White',
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

export const departmentMapper = (data: any) => {
  return Object.values(
    data.reduce((prev: any, row: any) => {
      return {
        ...prev,
        [row.departmentOfEnrolment]: {
          ...prev[row.departmentOfEnrolment],
          [row.sex]: row.numEnrolments,
          departmentOfEnrolment: row.departmentOfEnrolment,
        },
      };
    }, {}),
  )
    .map((row: any) => ({
      departmentOfEnrolment: row.departmentOfEnrolment,
      Intersex: row.Intersex || 0,
      Male: row.Male || 0,
      Female: row.Female || 0,
    }))
    .sort((a, b) => {
      const totalA = a.Male + a.Intersex + a.Female;
      const totalB = b.Male + b.Intersex + b.Female;
      if (totalA > totalB) {
        return -1;
      } else if (totalA === totalB) {
        return 1;
      } else {
        return 1;
      }
    }) as any;
};

import {
  enrolmentData,
  ageGroupData,
  departmentData,
  genderData,
  ehrData,
  populationData,
  signatureData,
} from '../data';

// const response = {
//   data: {
//     studyChart: {},
//   },
// };

const resolver = (variables: any) => {
  let response;
  if (variables.chart === 'enrolment') {
    response = {
      data: { fetchChart: { chart: variables.chart, data: enrolmentData } },
    };
  } else if (variables.chart === 'age') {
    response = {
      data: { fetchChart: { chart: variables.chart, data: ageGroupData } },
    };
  } else if (variables.chart === 'department') {
    response = {
      data: {
        fetchChart: { chart: variables.chart, data: departmentData },
      },
    };
  } else if (variables.chart === 'gender') {
    response = {
      data: { fetchChart: { chart: variables.chart, data: genderData } },
    };
  } else if (variables.chart === 'ehr') {
    response = {
      data: { fetchChart: { chart: variables.chart, data: ehrData } },
    };
  } else if (variables.chart === 'populationGroup') {
    response = {
      data: {
        fetchChart: { chart: variables.chart, data: populationData },
      },
    };
  } else if (variables.chart === 'signatures') {
    response = {
      data: { fetchChart: { chart: variables.chart, data: signatureData } },
    };
  }
  return response;
};
export default resolver;

import { createSlice } from '@reduxjs/toolkit';
import { KeyValues, StoreStatus, GraphQlError } from '../../core/types/common.types';
import { ParticipantProfile, WithdrawParticipantInput } from '../../core/types/participants.types';
import {
  getParticipantByHospitalNoThunk,
  adminWithdrawParticipantThunk,
  updateParticipantProfileThunk,
} from './thunks';

export interface ParticipantState {
  errors: GraphQlError[] | null;
  result: ParticipantProfile | null;
  status: StoreStatus;
  values: KeyValues | null;
  withdrawalValues: WithdrawParticipantInput | null;
  withdrawalError: string | null;
  withdrawalStatus: StoreStatus;
  withdrawalResult: WithdrawParticipantInput | null;
}
// Initial State
export const initialState: ParticipantState = {
  errors: null,
  result: null,
  status: StoreStatus.IDLE,
  values: null,
  withdrawalValues: null,
  withdrawalError: null,
  withdrawalStatus: StoreStatus.IDLE,
  withdrawalResult: null,
};

// Slice
export const participantSlice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    resetParticipantResult: (state) => ({
      ...state,
      status: initialState.status,
      error: initialState.errors,
      result: initialState.result,
    }),
    resetParticipantStore: () => ({
      ...initialState,
    }),
    stepForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
    }),
    withdrawalForward: (state, action) => ({
      ...state,
      withdrawalValues: { ...state.withdrawalValues, ...action.payload },
      withdrawalStatus: StoreStatus.IDLE,
      withdrawalError: null,
      withdrawalResult: null,
    }),
    withdrawalReset: (state) => ({
      ...state,
      withdrawalValues: null,
      withdrawalStatus: StoreStatus.IDLE,
      withdrawalError: null,
      withdrawalResult: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantByHospitalNoThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(getParticipantByHospitalNoThunk.fulfilled.type, (state, action: any) => {
      state.errors = null;
      state.result = action.payload;
      state.status = StoreStatus.IDLE;
    });
    builder.addCase(getParticipantByHospitalNoThunk.rejected.type, (state, action: any) => {
      state.errors = action.payload;
      state.result = null;
      state.status = StoreStatus.ERROR;
    });
    builder.addCase(updateParticipantProfileThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(updateParticipantProfileThunk.fulfilled.type, (state, action: any) => {
      state.errors = null;
      state.result = action.payload;
      state.status = StoreStatus.IDLE;
    });
    builder.addCase(updateParticipantProfileThunk.rejected.type, (state, action: any) => {
      state.errors = action.payload;
      state.result = null;
      state.status = StoreStatus.ERROR;
    });
    builder.addCase(adminWithdrawParticipantThunk.pending.type, (state) => {
      state.withdrawalStatus = StoreStatus.BUSY;
    });
    builder.addCase(adminWithdrawParticipantThunk.fulfilled.type, (state, action: any) => {
      state.withdrawalError = null;
      state.withdrawalResult = state.withdrawalValues;
      state.result = action.payload;
      state.withdrawalStatus = StoreStatus.IDLE;
    });
    builder.addCase(adminWithdrawParticipantThunk.rejected.type, (state, action: any) => {
      state.withdrawalError = action.payload;
      state.withdrawalResult = null;
      state.withdrawalStatus = StoreStatus.ERROR;
    });
  },
});

// Action Creators
export * from './thunks';
export * from './selectors';
export const { resetParticipantStore, resetParticipantResult, stepForward, withdrawalReset, withdrawalForward } =
  participantSlice.actions;
export default participantSlice.reducer;

export const response = {
  data: {
    reportEnrolmentsBySex: [
      {
        numEnrolments: 98,
        sex: 'Female',
      },
      {
        numEnrolments: 13,
        sex: 'Intersex',
      },
      {
        numEnrolments: 114,
        sex: 'Male',
      },
    ],
  },
};

const resolver = (variables: any) => {
  return response;
};

export default resolver;

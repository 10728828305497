import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  chartContainer: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartTitle: { flexGrow: 1 },
  chartLegend: { paddingTop: 20 },
}));

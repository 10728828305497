import React, { useEffect } from 'react';
import InnerLayout from '../../../../layout/Inner';
import Box from '@mui/material/Box';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { fetchSummaryThunk, isBusySelector, resultSelector } from '../../../../store/summary';
import { userProfileSelector } from '../../../../store/user';
import { filtersSelector, setFilters } from '../../../../store/monitoring';
import Heading1 from '../../../../components/text/Heading1';
import Heading2 from '../../../../components/text/Heading2';
import ReportLookup from '../../../../components/content/ReportLookup';
import SummaryStrip from './components/SummaryStrip';
import ROUTES from '../../../../core/constants/routes.constant';

function Home() {
  const { t } = useTranslation('portal');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSummaryBusy = useAppSelector(isBusySelector);
  const summary = useAppSelector(resultSelector);
  const profile = useAppSelector(userProfileSelector);
  const filters = useAppSelector(filtersSelector);

  useEffect(() => {
    dispatch(fetchSummaryThunk());
  }, [dispatch]);

  const handleFilter = (filters: any) => {
    dispatch(setFilters(filters));
    navigate(ROUTES.monitoring);
  };

  return (
    <InnerLayout title="Site" title2="Portal">
      {profile && (
        <Heading1>
          {t('home.welcome')}, <strong>{profile.firstName}</strong>
        </Heading1>
      )}

      <Box mb={4}>
        <Heading2 icon={SpeedOutlinedIcon}>{t('home.overviewTitle')}</Heading2>
        <SummaryStrip
          isBusy={isSummaryBusy}
          participantTotal={summary?.totalEnrolments}
          departmentTotal={summary?.distinctDepts}
          withdrawalTotal={summary?.totalWithdrawals}
        />
      </Box>

      <Box mb={4}>
        <Heading2 icon={BarChartOutlinedIcon}>{t('home.monitoringTitle')}</Heading2>
        <ReportLookup onFilter={handleFilter} initialValues={filters} />
      </Box>
    </InnerLayout>
  );
}

export default Home;

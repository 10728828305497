import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsPhone, BsLock } from 'react-icons/bs';
import { useFormik } from 'formik';
import ContentCard from '../../../../../../../../../../../components/content/ContentCard';
import { FormInput } from '@omnigenbiodata/ui';

export interface MobileFormProps {
  mobile: any;
  onSubmit: (values: any) => void;
}

function MobileForm({ mobile, onSubmit }: MobileFormProps) {
  const { t } = useTranslation('portal');
  const formik = useFormik({
    initialValues: {
      mobile: (mobile as string) || '',
    },
    enableReinitialize: true,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });

  return (
    <ContentCard>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <FormInput
            startAdornment={<BsPhone fontSize={20} style={{ marginRight: 10 }} />}
            endAdornment={<BsLock fontSize={20} style={{ marginLeft: 10 }} />}
            margin="none"
            error={formik.errors.mobile}
            name="mobile"
            label={t('mobile', { ns: 'forms' })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.mobile}
            value={formik.values.mobile}
            disabled
          />
        </div>
      </form>
    </ContentCard>
  );
}

export default MobileForm;

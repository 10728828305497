import React from 'react';
import TabPanel from '@mui/lab/TabPanel';
import ConsentFormBlock from './ConsentFormBlock';
import EnrolmentBlock from './EnrolmentBlock';
import { ParticipantProfile } from '../../../../../../../../../../core/types/participants.types';

export interface EnrolmentTabProps {
  profile: ParticipantProfile;
  onConsentLink: (event: any) => void;
  isConsentLinkBusy: boolean;
  hasConsentLinkError: boolean;
}

function EnrolmentTab({ profile, onConsentLink, isConsentLinkBusy, hasConsentLinkError }: EnrolmentTabProps) {
  return (
    <TabPanel value="2" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <EnrolmentBlock profile={profile} />
      <ConsentFormBlock
        onConsentLink={onConsentLink}
        isConsentLinkBusy={isConsentLinkBusy}
        hasConsentLinkError={hasConsentLinkError}
      />
    </TabPanel>
  );
}

export default EnrolmentTab;
